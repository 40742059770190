import { Button } from "antd";

const UpdateSkillMap = () => {
  return (
    <div className="comming-soon">
      <div className="comming-btn">
        <Button>Coming Soon</Button>
        <p>We are going to launch this feature soon</p>
      </div>
    </div>
  );
};
export default UpdateSkillMap;
