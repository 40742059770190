import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../api";
import Loader from "../../components/loader";
import { Button, Input, Progress, Radio, Space, message, Select } from "antd";

const { Option } = Select;

const AssessmentSampleQuestions = () => {
  const user = JSON?.parse(localStorage.getItem("user"));
  const [assessmentData, setAssessmentData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingFor, setLoadingFor] = useState("");

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchQuestions(id);
    }
  }, [id]);

  const fetchQuestions = async (assessmentId) => {
    setLoading(true);
    setLoadingFor("Getting Sample Questions");
    try {
      const response = await fetch(
        `${BASE_URL}/api/assessment-questions/${assessmentId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${user?.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        const questions = data.data.questions;
        console.log(questions, "questions");
        setQuestions(questions);
      } else {
        console.error("Failed to retrieve Sample Questions:", data.message);
      }
    } catch (error) {
      console.error("Error fetching Sample Questions:", error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  function removeNumber(str) {
    if (str) {
      const regex = /^\d+\.\s*/;
      const result = str?.replace(regex, "");

      return result;
    }
    return "";
  }

  const fetchAssessmentData = async (assessmentId) => {
    setLoading(true);
    setLoadingFor("Getting Assessment Data");
    try {
      const response = await fetch(
        `${BASE_URL}/api/assessment/${assessmentId}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${user?.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        const assessment = data.data;
        console.log(assessment, "assessment");
        setAssessmentData(assessment);
      } else {
        console.error("Failed to retrieve assessment:", data.message);
      }
    } catch (error) {
      console.error("Error fetching assessment data:", error.message);
    } finally {
      setLoading(false);
      setLoadingFor("");
    }
  };

  function cleanString(str) {
    if (str) {
      return str?.replace(/^[a-zA-Z]\)|^[a-zA-Z]\./, "")?.trim();
    }
    return "";
  }

  return (
    <div className="mb-4">
      <div class="headingbar d-flex justify-content-between">
        <div class="title">
          <h2>Sample Assessment Questions</h2>
        </div>
      </div>
      <div className="assign-assessment">
        <ul className="sample-preview">
          {questions.map((question, index) => (
            <li key={index}>
              <div className="dev-title d-flex align-items-center justify-content-between">
                <h6>{question?.skill_name}</h6>
              </div>

              <div className="dev-test-question">
                <h4>
                  {index + 1}. {""}
                  {removeNumber(question?.question)}
                </h4>
                <div className="question-list">
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value="A">
                        {cleanString(question?.option1) || "none"}
                      </Radio>
                      <Radio value="B">
                        {cleanString(question?.option2) || "none"}
                      </Radio>
                      <Radio value="C">
                        {cleanString(question?.option3) || "none"}
                      </Radio>
                      <Radio value="D">
                        {cleanString(question?.option4) || "none"}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {loading && <Loader text={loadingFor} />}
      </div>
    </div>
  );
};

export default AssessmentSampleQuestions;
