import { useEffect, useState } from "react";
import { callGetApi, callGetDomains, callTestApi } from "../../services/roadmap_api";
import DashboardExpert from "../component/domain-expert/expert-dashboard/dashboard-expert";
import { useDispatch, useSelector } from "react-redux";
import {
  storeAllDomains,
  storeGetList,
  storeRoleCount,
  storeSelValues,
  storeSelectedDomain,
  storeTestStatus,
  storeUserDomains,
} from "../../store/domainExpert";
import SkillStepsList from "../component/domain-expert/mentor-skill-type/skill-steps";
import QuestionList from "../component/domain-expert/question-management/question-manage-list";
import Loader from "../../components/loader";
import { toMessage } from "../utils";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import NotSelectedRole from "../modal/no-jobRole-selected";

const DashboardMentors = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();

  const [rolesBackup, setRolesBackup] = useState([]);
  const [loadingFor, setLoadingFor] = useState("");
  const [show, setShow] = useState(false);
  const [searchParams] = useSearchParams();

  const { selectedDomain, tabStatus, getList, userDomains } = useSelector(
    (state) => state?.domainExpert
  );

  const getAllJobRoles = async () => {
    setLoadingFor("Retrieving all job roles for experts...");
    const res = await callGetApi(
      `/api/domain-expert/sector/target-roles/?sector=${selectedDomain?.label}`
    );
    const res2 = await callGetApi(`/api/domain-expert/domain_expert/`);
    if (res) {
      const getRoleName = res2?.domain_expert_data?.map(
        (it) => it?.target_role_name
      );
      const getThisRoleName = res?.map((it) => it?.name);
      const filterdres2Roles = res2?.domain_expert_data?.filter((it) =>
        getThisRoleName?.includes(it?.target_role_name)
      );

      console.log(filterdres2Roles, "responssss");

      const filterdRoles = res?.filter(
        (it) => !getRoleName?.includes(it?.name)
      );
      const finalRoles = filterdres2Roles.concat(
        filterdRoles.map((it) => ({ target_role_name: it?.name }))
      );

      dispatch(storeAllDomains({ type: "role", data: finalRoles }));
      dispatch(storeGetList(false));
      setLoadingFor("");
      const countCertified = finalRoles?.reduce((count, item) => {
        if (item.status === "certified") {
          return count + 1;
        }
        return count;
      }, 0);
      dispatch(
        storeRoleCount({
          total: res?.length,
          certified: countCertified,
          non_certified: res?.length - countCertified,
        })
      );
      setRolesBackup(finalRoles);
    }
  };
  
  useEffect(() => {
    console.log(selectedDomain,userDomains,"selectedDomain")
    if (selectedDomain) getAllJobRoles();
  }, [selectedDomain, getList]);

  
  useEffect(() => {
    const role = searchParams.get('role');
    if (role) {
      const decodedRole = decodeURIComponent(role);

      dispatch(storeTestStatus("steps"));
      if(decodedRole=="no_role"){
        setShow(true)
      }
     
        dispatch(
          storeSelValues({
            type: "role",
            value: { label: decodedRole, value: decodedRole },
          })
        );
      
    }else{
      navigate("/domain-expert-dashboard");
      dispatch(storeTestStatus("list"));
    }
  }, [searchParams]);

  return (
    <>
      {loadingFor !== "" ? <Loader text={loadingFor} /> : ""}

      {tabStatus === "list" ? (
        <DashboardExpert rolesBackup={rolesBackup} />
      ) : tabStatus === "steps" ? (
        <SkillStepsList />
      ) : (
        <QuestionList />
      )}
      <NotSelectedRole show={show} handleClose={()=>setShow(false)} />
    </>
  );
};
export default DashboardMentors;
