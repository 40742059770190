import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../assets/images/logo.png";
import { Tooltip } from "antd";

export const isObjectsEqual = (obj1, obj2) => {
  const labels1 = Object.values(obj1).map((item) => item?.label);
  const labels2 = Object.values(obj2).map((item) => item?.label);

  labels1.sort();
  labels2.sort();

  return JSON.stringify(labels1) === JSON.stringify(labels2);
};

export const toMessage = (message, type) => {
  if (type == "warning") {
    toast.warning(message, {
      toastId: message,
    });
  } else if (type == "error") {
    toast.error(message, {
      toastId: message,
    });
  } else if (type == "success") {
    toast.success(message, {
      toastId: message,
    });
  }
};

export const getCoreLevel = (selectedAnswer, count, type) => {
  console.log(type, "type");
  let medium = 0,
    mediumCorrect = 0,
    init = 0,
    initCorrect = 0,
    advance = 0,
    advanceCorrect = 0;

  Object.values(selectedAnswer)?.forEach((answer) => {
    switch (answer?.level) {
      case "intermediate":
        medium++;
        if (answer.isCorrect) mediumCorrect++;
        break;
      case "basic":
        init++;
        if (answer.isCorrect) initCorrect++;
        break;
      case "advanced":
        advance++;
        if (answer.isCorrect) advanceCorrect++;
        break;
      default:
        break;
    }
  });

  let totalCount = medium * 2 + init + advance * 3;
  let get = mediumCorrect * 2 + initCorrect + advanceCorrect * 3 + count;
  if (type && type == "pause") {
    return get;
  }
  let result = (get / totalCount) * 100;

  console.log("actual result :", result);

  if (result > 80 && advanceCorrect === 0) {
    result = 80; // Lower the result to fall into the next level range
  }
  if (result <= 80 && result > 40 && mediumCorrect === 0) {
    result = 40;
  }

  console.log("analyse result :", result);

  if (result <= 40) {
    return { level: "basic", percentage: result < 19 ? 22 : result };
  } else if (result <= 80) {
    return { level: "intermediate", percentage: result };
  } else {
    return { level: "advanced", percentage: 100 };
  }
};

export const getTecLevel = (selectedAnswer, count, type) => {
  let level1 = 0,
    level1Correct = 0,
    level2 = 0,
    level2Correct = 0,
    level3 = 0,
    level3Correct = 0,
    level4 = 0,
    level4Correct = 0,
    level5 = 0,
    level5Correct = 0,
    level6 = 0,
    level6Correct = 0;

  Object.values(selectedAnswer)?.forEach((answer) => {
    switch (answer?.level) {
      case "level1":
        level1++;
        if (answer.isCorrect) level1Correct++;
        break;
      case "level2":
        level2++;
        if (answer.isCorrect) level2Correct++;
        break;
      case "level3":
        level3++;
        if (answer.isCorrect) level3Correct++;
        break;
      case "level4":
        level4++;
        if (answer.isCorrect) level4Correct++;
        break;
      case "level5":
        level5++;
        if (answer.isCorrect) level5Correct++;
        break;
      case "level6":
        level6++;
        if (answer.isCorrect) level6Correct++;
        break;
      default:
        break;
    }
  });

  let totalCount =
    level1 + level2 * 2 + level3 * 3 + level4 * 4 + level5 * 5 + level6 * 6;
  let correctAnsCount =
    level1Correct +
    level2Correct * 2 +
    level3Correct * 3 +
    level4Correct * 4 +
    level5Correct * 5 +
    level6Correct * 6 +
    count;
  if (type && type == "pause") {
    return correctAnsCount;
  }
  let result = (correctAnsCount / totalCount) * 100;

  console.log("actual result :", result);

  if (result > 90 && level6Correct === 0) {
    result = 90; // Lower the result to fall into the next level range
  }
  if (result <= 90 && result > 80 && level5Correct === 0) {
    result = 80;
  }
  if (result <= 80 && result > 60 && level4Correct === 0) {
    result = 60;
  }
  if (result <= 60 && result > 40 && level3Correct === 0) {
    result = 40;
  }
  if (result <= 40 && result > 20 && level2Correct === 0) {
    result = 20;
  }

  console.log("analyse result :", result);

  if (result <= 20) {
    return { level: "1", percentage: result < 11 ? 16 : result };
  } else if (result <= 40) {
    return { level: "2", percentage: result };
  } else if (result <= 60) {
    return { level: "3", percentage: result };
  } else if (result <= 80) {
    return { level: "4", percentage: result };
  } else if (result <= 90) {
    return { level: "5", percentage: result };
  } else {
    return { level: "6", percentage: 100 };
  }
};

export const getPercentage = (val1, val2) => {
  const userProf = parseInt(val1.match(/\d+/)) || 0;
  const reqProf = parseInt(val2.match(/\d+/)) || 0;

  if (isNaN(userProf) || isNaN(reqProf)) {
    return 0;
  }

  let result = (userProf / reqProf) * 100;
  result = Math.max(0, Math.min(100, result));
  return result;
};

export const getSkillGap = (skills) => {
  const total = skills?.length || 0;
  const proficient = skills?.filter((it) => it?.status)?.length || 0;

  const covered = Math.min((proficient / total) * 100, 100);
  const gap = 100 - covered;

  return { covered, gap };
};

export const getCurrentTec = (name, type, userSkills, showFull) => {
  const userSkill = userSkills?.find(
    (it) =>
      (showFull
        ? showFull
        : it.skill_type.toLowerCase() === type?.toLowerCase()) &&
      it.skill_name?.toLowerCase() === name?.toLowerCase()
  );
  console.log(userSkill, "skillData");
  if (userSkill && userSkill.current_proficiency) {
    return type === "TechnicalSkills" || type === "tsc"
      ? Number(userSkill.current_proficiency) + 0.2
      : getProfNum(userSkill.current_proficiency) + 0.1;
  } else {
    return 0;
  }
};

export const getCurrent = (name, type, userSkills, showFull) => {
  console.log(userSkills, "skillData");
  const userSkill = userSkills?.find(
    (it) =>
      (showFull
        ? showFull
        : it?.skill_type ===
          (type === "tsc" || type === "TechnicalSkills"
            ? "tsc" || "TechnicalSkills"
            : "CoreSkills" || "ccs")) &&
      it.skill_name?.toLowerCase() === name?.toLowerCase()
  );

  if (userSkill && userSkill.current_proficiency) {
    return type === "TechnicalSkills" || type === "tsc"
      ? Number(userSkill.current_proficiency) + 0.1
      : getProfNum(userSkill.current_proficiency) + 0.1;
  } else {
    return 0;
  }
};

export const getStatus = (name, userSkills) => {
  const userSkill = userSkills?.find(
    (it) => it.skill_name?.toLowerCase() === name?.toLowerCase()
  );
  return userSkill?.status || false;
};

export const getProfNum = (prof) => {
  const arr = ["basic", "intermediate", "advanced"];
  const index = arr?.indexOf(prof?.toLowerCase()) + 1;
  return index > 0 ? index : 1;
};

export const getProfNum0 = (prof) => {
  const arr = ["basic", "intermediate", "advanced"];
  const index = arr.indexOf(prof?.toLowerCase()) + 1;
  return index > 0 ? index : 0;
};

export const handleDownloadPDF = async (
  chartRef,
  title,
  companyName,
  jobRole,
  userCount,
  setCurrentPage,
  dataPerPage
) => {
  try {
    const totalPages = Math.ceil(userCount / dataPerPage);

    let images = [];

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    for (let page = 0; page < totalPages; page++) {
      setCurrentPage(page);
      await delay(1000);
      const canvasImage = await html2canvas(chartRef.current, {
        scale: window.devicePixelRatio,
        useCORS: true,
        logging: true, // Optional: for debugging
        windowHeight: chartRef.current.scrollHeight,
        windowWidth: chartRef.current.scrollWidth * 1.4,
      });
      images.push(canvasImage.toDataURL("image/png"));
    }

    // Initialize PDF
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: "a4", // Set format according to your requirement
    });

    // Variables for layout calculation
    const margin = 20; // Margin between images
    const headerHeight = 90; // Space taken by the header
    const footerHeight = 30;
    const pageHeight = pdf.internal.pageSize.getHeight();
    const pageWidth = pdf.internal.pageSize.getWidth();
    const yPosHeader = 30; // Y position for the header texts
    const yPosFooter = pageHeight - footerHeight + 10; // Y position for the footer text
    let yPos = 110; // Start position for the first image after the header

    // Function to add header text to the current page
    const addHeader = () => {
      const logoWidth = 50; // Set the width of the logo
      const logoHeight = 50; // Set the height of the logo
      const headerFontSize = 22;
      const subHeaderFontSize = 18;

      // Add logo
      pdf.addImage(logo, "PNG", margin, margin, logoWidth, logoHeight);

      // Set font size for header
      // pdf.setFontSize(headerFontSize);
      // const titleWidth = pdf.getTextWidth(title);
      // pdf.text(title, (pageWidth - titleWidth) / 2, yPosHeader);

      // Set font size for subheaders
      pdf.setFontSize(subHeaderFontSize);
      const subHeaders = [
        `Company: ${companyName}`,
        `Role: ${jobRole}`,
        `${title}`,
        `Assessment Users: ${userCount}`,
      ];

      subHeaders.forEach((text, index) => {
        const textWidth = pdf.getTextWidth(text);
        pdf.text(
          text,
          (pageWidth - textWidth) / 2,
          yPosHeader + (index + 1) * 16
        );
      });
    };

    // Function to add footer text to the current page
    const addFooter = () => {
      const footerText =
        "Copyright © 2024 - 2024 Skillmotion.ai. All Rights Reserved. To report contact info@skillmotion.ai";
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "bold");
      const footerTextWidth = pdf.getTextWidth(footerText);
      pdf.text(footerText, (pageWidth - footerTextWidth) / 2, yPosFooter);
    };

    addHeader(); // Add header to the first page

    images.forEach((imgData, index) => {
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pageWidth - 2 * margin;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Check if the image fits in the current page
      if (yPos + pdfHeight + margin > pageHeight) {
        pdf.addPage(); // Add new page if the image doesn't fit
        yPos = 110; // Reset yPos for new page

        addHeader(); // Add header on each new page
      }

      // Add image to PDF
      pdf.addImage(imgData, "PNG", margin, yPos, pdfWidth, pdfHeight);
      yPos += pdfHeight + margin; // Increment position for next image
      addFooter();
    });

    // Save the new PDF
    pdf.save(`${title}.pdf`);
  } catch (error) {
    toast.error(`Failed to download PDF: ${error.message}`);
  }
};

export const handleOnlyChartPDF = async (
  chartRef,
  title,
  companyName
) => {
  try {
    const canvasImage = await html2canvas(chartRef.current, {
      scale: window.devicePixelRatio,
      useCORS: true,
      logging: true, // Optional: for debugging
      windowHeight: chartRef.current.scrollHeight,
      windowWidth: chartRef.current.scrollWidth * 1.4,
    });
    const imageData = canvasImage.toDataURL("image/png");

    // Initialize PDF
    const pdf = new jsPDF({
      unit: "px",
      format: "a4", // Set format according to your requirement
    });

     // Variables for layout calculation
     const margin = 20; // Margin between images
     const headerHeight = 90; // Space taken by the header
     const footerHeight = 30;
     const pageHeight = pdf.internal.pageSize.getHeight();
     const pageWidth = pdf.internal.pageSize.getWidth();
     const yPosHeader = 30; // Y position for the header texts
     const yPosFooter = pageHeight - footerHeight + 10; // Y position for the footer text
     let yPos = 80; // Start position for the first image after the header

     const addHeader = () => {
      const logoWidth = 50; // Set the width of the logo
      const logoHeight = 50; // Set the height of the logo
      const headerFontSize = 22;
      const subHeaderFontSize = 18;
      pdf.addImage(logo, "PNG", margin, margin, logoWidth, logoHeight);
      pdf.setFontSize(subHeaderFontSize);
      const subHeaders = [
        `Company: ${companyName}`,
        `${title}`,
      ];

      subHeaders.forEach((text, index) => {
        const textWidth = pdf.getTextWidth(text);
        pdf.text(
          text,
          (pageWidth - textWidth) / 2,
          yPosHeader + (index + 1) * 16
        );
      });
    };

    // Function to add footer text to the current page
    const addFooter = () => {
      const footerText =
        "Copyright © 2024 - 2024 Skillmotion.ai. All Rights Reserved. To report contact info@skillmotion.ai";
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "bold");
      const footerTextWidth = pdf.getTextWidth(footerText);
      pdf.text(footerText, (pageWidth - footerTextWidth) / 2, yPosFooter);
    };

    addHeader(); 
    const imgProps = pdf.getImageProperties(imageData);
    const pdfWidth = pageWidth - 2 * margin;
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
    pdf.addImage(imageData, "PNG", margin, yPos, pdfWidth , pdfHeight * 0.8);
    yPos += pdfHeight + margin; // Increment position for next image
    addFooter();
    // Save the new PDF
    pdf.save(`${title}.pdf`);
  } catch (error) {
    toast.error(`Failed to download PDF: ${error.message}`);
  }
};


export const uniqueByTargetRoleName = (array) => {
  if (array && array?.length > 0) {
    const seen = new Set();
    return array?.filter((item) => {
      const targetRoleName = item?.target_role_name?.toLowerCase();
      return seen?.has(targetRoleName) ? false : seen?.add(targetRoleName);
    });
  } else {
    return [];
  }
};

export const uniqueSkillName = (array) => {
  if (array && array?.length > 0) {
    const seen = new Set();
    return array?.filter((item) => {
      const skill = item?.skill?.toLowerCase();
      return seen?.has(skill) ? false : seen?.add(skill);
    });
  } else {
    return [];
  }
};

export const uniqueByTargetRoleNameDashboard = (array) => {
  const seen = new Set();
  return array.filter((item) => {
    const targetRoleName = item?.label?.toLowerCase();
    return seen.has(targetRoleName) ? false : seen.add(targetRoleName);
  });
};

export const capitalizeFirstLetter = (input) => {
  console.log(input, "dstrrrrrr");
  if (input && input !== undefined) {
    const capitalize = (str) => {
      return str?.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    if (typeof input === "string") {
      return capitalize(input);
    } else if (input && typeof input === "object") {
      return capitalize(input?.label || input?.value);
    }
  }
  return "";
};

export function truncateTextAfterWords(text) {
  if (text) {
    const words = text?.split(" ");

    if (words.length <= 19) {
      return text;
    }

    const truncatedWords = words?.slice(0, 19);
    return truncatedWords?.join(" ") + "...";
  }
  return "";
}

export function truncateTextAfterWordsWithLength(text, length) {
  if (text) {
    const words = text?.split("");
    if (words.length <= length) {
      return text;
    }

    const truncatedWords = words.slice(0, length);
    return truncatedWords.join("") + "...";
  }
  return "";
}

export function removeDuplicateNameSkills(skills) {
  const uniqueSkills = [];
  if (skills && skills?.length > 0) {
    const skillNames = new Set();

    for (const skill of skills) {
      if (!skillNames.has(skill)) {
        uniqueSkills.push(skill);
        skillNames.add(skill);
      }
    }
  }
  return uniqueSkills;
}

export function removeDuplicateSkills(skills) {
  const uniqueSkills = [];
  if (skills && skills?.length > 0) {
    const skillNames = new Set();

    for (const skill of skills) {
      if (!skillNames.has(skill.skillName)) {
        uniqueSkills.push(skill);
        skillNames.add(skill.skillName);
      }
    }
  }
  return uniqueSkills;
}

export const removeExtraSkills = (required = [], current = []) => {
  const requiredSkillNames = new Set(required.map((it) => it.skillName));
  let remainingSkills = removeDuplicateSkills(
    current.filter((it) => requiredSkillNames.has(it.skillName))
  );
  remainingSkills = remainingSkills.filter(
    (it) => it?.skillProficiencies != "" || it?.skillProficiencies != 0
  );
  // console.log(remainingSkills,"remainingSkills")
  return remainingSkills;
};

function getRandomNumber() {
  return Math.floor(Math.random() * (40 - 25 + 1)) + 25;
}
function getNumber() {
  return Math.floor(Math.random() * 28) + 1;
}

export const randomDescriptions = [
  "Responsible for ensuring efficient operations and achieving organizational goals, this role plays a critical role in driving business success through strategic planning, analysis, and execution of key tasks.",
  "Focused on maintaining high standards and delivering quality results, this position involves strategic planning, analysis, and execution to support organizational objectives.",
  "Tasked with overseeing important operations, this role is pivotal in implementing strategies, analyzing performance, and ensuring the achievement of business goals.",
  "Involved in the strategic aspects of business operations, this role ensures optimal performance and goal attainment through careful planning and execution.",
  "Dedicated to achieving operational excellence and business success, this position requires strategic planning, thorough analysis, and effective execution of key initiatives.",
  "Key to the success of the organization, this role involves detailed planning, comprehensive analysis, and precise execution to meet and exceed business objectives.",
];

export const filteredNameSkills = (skills) => {
  if (Array.isArray(skills)) {
    if (
      Array.isArray(skills) &&
      skills.length > 0 &&
      typeof skills[0] === "object" &&
      "soft_skills" in skills[0] &&
      "technical_skills" in skills[0]
    ) {
      let coreSkills = skills[0]?.soft_skills?.map(
        (it) => it?.name || "Communication"
      );
      let tecSkills = skills[0]?.technical_skills?.map(
        (it) => it?.name || "Next js"
      );

      return coreSkills?.concat(tecSkills) || [];
    } else if (
      Array.isArray(skills) &&
      skills.every((item) => typeof item === "object" && "name" in item)
    ) {
      let updatedkills = skills?.map((it) => it?.name || "Communication");
      return updatedkills;
    } else {
      return ["Communication"];
    }
  } else {
    let coreSkills = skills?.soft_skills?.map(
      (it) => it?.name || "Communication"
    );
    let tecSkills = skills?.technical_skills?.map(
      (it) => it?.name || "Next js"
    );
    return coreSkills?.concat(tecSkills) || [];
  }
};

export const removeHyphens = (id) => {
  return id?.replace(/-/g, "");
};

export const getCurrentCoreRadarSkill = (name, userSkills) => {
  const userSkill = userSkills?.find(
    (it) =>
      it?.skill_type == "ccs" &&
      it.skill_name?.toLowerCase() === name?.toLowerCase()
  );
  if (userSkill && userSkill?.current_proficiency) {
    return getProfNum0(userSkill.current_proficiency);
  } else {
    return 0;
  }
};

export const getCurrentTecRadarSkill = (name, userSkills) => {
  const userSkill = userSkills?.find(
    (it) =>
      it?.skill_type == "tsc" &&
      it.skill_name?.toLowerCase() === name?.toLowerCase()
  );
  if (userSkill && userSkill?.current_proficiency) {
    return Number(userSkill.current_proficiency);
  } else {
    return 0;
  }
};

export const getCurrentTecChartSkill = (name, userSkills) => {
  const userSkill = userSkills?.find(
    (it) =>
      it?.skill_type == "tsc" &&
      it.skill_name?.toLowerCase() === name?.toLowerCase()
  );
  if (userSkill && userSkill?.current_proficiency) {
    return Number(userSkill?.current_proficiency) + 0.2;
  } else {
    return 0;
  }
};

export const getCoreMap = (skill, cSkill) => {
  let ls = skill?.toLowerCase();
  let cs = cSkill?.toLowerCase();
  let arr = ["Basic", "Intermediate", "Advance"];
  console.log(ls, cs, "core skillsssss");

  let res = arr?.map((it, i) => {
    return (
      <Tooltip title={it} key={i}>
        <span
          // className='skill-box Intermediate advance blank-fill'
          className={`skill-box ${
            (cs == "advance" ||
              cs == "basic" ||
              cs == "intermediate" ||
              cs == "advanced") &&
            i == 0
              ? "advance"
              : (ls == "advance" ||
                  ls == "basic" ||
                  ls == "intermediate" ||
                  ls == "advanced") &&
                i == 0
              ? "Intermediate"
              : (cs == "intermediate" || cs == "advance" || cs == "advanced") &&
                i == 1
              ? "advance"
              : (ls == "intermediate" || ls == "advance" || ls == "advanced") &&
                i == 1
              ? "Intermediate"
              : (cs == "advance" || cs == "advanced") && i == 2
              ? "advance"
              : (ls == "advance" || ls == "advanced") && i == 2
              ? "Intermediate"
              : "blank-fill"
          }`}
        ></span>
      </Tooltip>
    );
  });
  return res;
};

export const getTecMap = (ls, cs) => {
  let arr = ["1", "2", "3", "4", "5", "6"];
  console.log(ls, cs, "core skillsssss");

  let res = arr?.map((it, i) => {
    return (
      <Tooltip title={it} key={i}>
        <span
          // className='skill-box blank-skill progress-skill fill-skill'
          className={`skill-box ${
            cs > 0 && cs <= 6 && i == 0
              ? "fill-skill"
              : ls > 0 && ls <= 6 && i == 0
              ? "progress-skill"
              : cs > 1 && cs <= 6 && i == 1
              ? "fill-skill"
              : ls > 1 && ls <= 6 && i == 1
              ? "progress-skill"
              : cs > 2 && cs <= 6 && i == 2
              ? "fill-skill"
              : ls > 2 && ls <= 6 && i == 2
              ? "progress-skill"
              : cs > 3 && cs <= 6 && i == 3
              ? "fill-skill"
              : ls > 3 && ls <= 6 && i == 3
              ? "progress-skill"
              : cs > 4 && cs <= 6 && i == 4
              ? "fill-skill"
              : ls > 4 && ls <= 6 && i == 4
              ? "progress-skill"
              : cs == 6 && i == 5
              ? "fill-skill"
              : ls == 6 && i == 5
              ? "progress-skill"
              : "blank-skill"
          }`}
        ></span>
      </Tooltip>
    );
  });
  return res;
};

// export const getCoreMap = (skill,cSkill) => {
//   let ls = skill?.toLowerCase();
//   let cs = cSkill?.toLowerCase();
//   let arr = ["Basic", "Intermediate", "Advance"];
//   console.log(ls,cs,"core skillsssss");

//   let res = arr?.map((it, i) => {
//     return (
//       <Tooltip title={it} key={i}>
//         <span
//           className={`skill-box ${
//             (ls == " advance" ||
//               ls == "blank-fill" ||
//               ls == "intermediate" ||
//               ls == "advanced") &&
//             i == 0
//               ? "advance"
//               : (ls == "intermediate" || ls == "advance" || ls == "advanced") &&
//                 i == 1
//               ? "Intermediate"
//               : (ls == "advance" || ls == "advanced") && i == 2
//               ? "blank-fill"
//               : "blank"
//           }`}
//         ></span>
//       </Tooltip>
//     );
//   });
//   return res;
// };

// export const getTecMap = (ls) => {
//   let arr = ["1", "2", "3", "4", "5", "6"];
//   console.log(ls, cs, "core skillsssss");

//   let res = arr?.map((it, i) => {
//     return (
//       <Tooltip title={it} key={i}>
//         <span
//           className={`skill-box ${
//             ls > 0 && ls <= 6 && i == 0
//               ? "fill-skill"
//               : ls > 1 && ls <= 6 && i == 1
//               ? "fill-skill"
//               : ls > 2 && ls <= 6 && i == 2
//               ? "progress-skill"
//               : ls > 3 && ls <= 6 && i == 3
//               ? "blank-skill"
//               : ls > 4 && ls <= 6 && i == 4
//               ? "blank-skill"
//               : ls == 6 && i == 5
//               ? "blank-skill"
//               : "blank"
//           }`}
//         ></span>
//       </Tooltip>
//     );
//   });
//   return res;
// };

export function filterObjByIds(arr, obj) {
  const filteredObj = {};
  arr.forEach((id) => {
    if (obj.hasOwnProperty(id)) {
      filteredObj[id] = obj[id];
    }
  });
  return filteredObj;
}

export const getCoreSingleMap = (skill, type) => {
  let ls = skill?.toLowerCase();
  let arr = ["Basic", "Intermediate", "Advance"];
  let res = arr?.map((it, i) => {
    return (
      <Tooltip title={it} key={i}>
        <span
          className={`skill-box ${
            (ls == "basic" ||
              ls == "intermediate" ||
              ls == "advance" ||
              ls == "advanced") &&
            i == 0
              ? `${type ? "Intermediate" : "advance"}`
              : (ls == "intermediate" || ls == "advance" || ls == "advanced") &&
                i == 1
              ? `${type ? "Intermediate" : "advance"}`
              : (ls == "advance" || ls == "advanced") && i == 2
              ? `${type ? "Intermediate" : "advance"}`
              : "blank"
          }`}
        ></span>
      </Tooltip>
    );
  });
  return res;
};

export const getTecSingleMap = (skill, type) => {
  let ls = skill;
  let arr = ["1", "2", "3", "4", "5", "6"];
  let res = arr?.map((it, i) => {
    return (
      <Tooltip title={it} key={i}>
        <span
          className={`skill-box ${
            ls > 0 && ls <= 6 && i == 0
              ? `${type ? "progress-skill" : "fill-skill"}`
              : ls > 1 && ls <= 6 && i == 1
              ? `${type ? "progress-skill" : "fill-skill"}`
              : ls > 2 && ls <= 6 && i == 2
              ? `${type ? "progress-skill" : "fill-skill"}`
              : ls > 3 && ls <= 6 && i == 3
              ? `${type ? "progress-skill" : "fill-skill"}`
              : ls > 4 && ls <= 6 && i == 4
              ? `${type ? "progress-skill" : "fill-skill"}`
              : ls == 6 && i == 5
              ? `${type ? "progress-skill" : "fill-skill"}`
              : "blank-skill"
          }`}
        ></span>
      </Tooltip>
    );
  });
  return res;
};
