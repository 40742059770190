import {  Modal } from "antd";
import React from "react";

const LearningPath = ({ show, handleClose,selectedPath, setSelectedPath,handlePopup }) => {


  return (
    <div>
      <Modal
        title="Choose Your Learning Path"
        visible={show}
        okText={"Confirm"}
        onOk={handlePopup}
        onCancel={handleClose}
        closeIcon={false}
        className="sma-modal"
      >
        <div className="subtitle-modal">
          Select the approach that best suits your learning goals.
        </div>
        <div className="list-box">
          <div className="input-box">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              checked={selectedPath === "generate"}
            />
            <label
              onClick={() => setSelectedPath("generate")}
              className="form-check-label"
              htmlFor="flexRadioDefault1"
            >
              Continue with Generic Content Plan
            </label>
          </div>
          <div className="input-box">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              checked={selectedPath === "personal"}
            />
            <label
              onClick={() => setSelectedPath("personal")}
              className="form-check-label"
              htmlFor="flexRadioDefault1"
            >
              Personalized Content Plan
            </label>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LearningPath;
