import { Button, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../components/dropdown";
import { jobListOption } from "../../constant";
import { useDispatch } from "react-redux";
import { storeSelValues } from "../../store/skillRenewal";
import { useNavigate } from "react-router-dom";

const NotSelectedRole = ({show ,handleClose}) => {
    const navigate=useNavigate();

  const handleOut=()=>{
    console.log("hello");
    navigate('/domain-expert-dashboard');
    handleClose();
  }
  return (
    <div>
      <Modal
        visible={show}
        onOk={handleClose}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleOut}
        cancelButtonProps={{ style: { display: "none" } }}
        className="skill-qs-modal validate-modal"
        width={600}
      >
        <div className="test-question-modal">
          <h2 className="title">
            Job Role Not Selected
          </h2>
          <div className="text-validate-box">
            <p>
              Please choose a job role from the dashboard to certify the job roles and their questions.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NotSelectedRole;
