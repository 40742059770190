import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Table,
  InputNumber,
  Button,
  Input,
  message,
  Menu,
  Dropdown
} from "antd";
import MultiSelect from "../component/MutliSelect";
import { BASE_URL } from "../../api";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom"; 

const AssessmentList = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(false);
  let user = JSON?.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchAssessments();
  }, []);

  const fetchAssessments = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/assessment-list/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`, // Ensure user token is available
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setAssessments(data.data);
        message.success("Assessments fetched successfully.");
      } else {
        message.error("Failed to retrieve assessments.");
      }
    } catch (error) {
      message.error(`Error fetching assessments: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Assessment Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Domain",
      dataIndex: "domain_name",
      key: "domain_name",
      sorter: true,
    },
    {
      title: "Talent Pool",
      dataIndex: "talent_pools",
      key: "talent_pools",
    },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "skills",
      render: (skills) => (
        <ul className="assessment-skills-list">
          {skills.map((skill) => (
            <li key={skill.name}>
              {skill.name} - Proficiency: {skill.proficiency}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (status ? <span class='success-text'>Active</span>: <span class='danger-text'>Inactive</span>),
    },
    {
      title: "Actions",
      key: "actions",
      render: (row) => {
        const menu = (
          <Menu>
            <Menu.Item key="1">
              <Link to={`/domain-expert-dashboard/assessment/${row.id}/`}>
                <i className="fa fa-edit option-icon"></i> Edit
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to={`/domain-expert-dashboard/assessment/assign/${row.id}/`}>
                <i className="fa fa-tasks option-icon"></i> Assign Talent Pool
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to={`/domain-expert-dashboard/assessment/sample-questions/${row.id}/`}>
                <i className="fa fa-tasks option-icon"></i> View Sample Questions
              </Link>
            </Menu.Item>
            <Menu.Item key="3">              
            <a onClick={() => handleToggleStatus(row.id, !row.status)} className={row.status ? "danger-text" : "success-text"}>
              <i className="fa fa-toggle-off toggle-icon option-icon"></i>{" "}
              {row.status ? "Inactivate" : "Activate"}
            </a>
            </Menu.Item>
          </Menu>
        );
  
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              Select an option <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const handleToggleStatus = async(id, status) => {
    console.log("assessment id", id);
    console.log("assessment status", status);
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/assessment-update-status/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${user?.token}`, // Ensure user token is available
        },
        body: JSON.stringify({"id": id, "status": status}),

      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        setAssessments(data.data);
        message.success("Assessments fetched successfully.");
      } else {
        message.error("Failed to retrieve assessments.");
      }
    } catch (error) {
      message.error(`Error fetching assessments: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Table
      columns={columns}
      dataSource={assessments}
      rowKey={(record) => record.name}
      loading={loading}
      pagination={{ pageSize: 10 }}
    />
  );
};
export default AssessmentList;
