import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../api";
import SocialShare from "./SocialShare";

function Footer() {
    const [footerdata, setFooterdata] = useState('');
    const [footerMenu, setFooterMenu] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`${BASE_URL}/api/footer/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch career data');
                }
                const data = await response.json();
                setFooterdata(data);
            } catch (error) {
                console.error('Error fetching footer data:', error);
            }
        };

        const fetchFooterMenu = async () => {
            try {

                const response = await fetch(`${BASE_URL}/api/footer-menu/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch career data');
                }
                const data = await response.json();
                setFooterMenu(data);
            } catch (error) {
                console.error('Error fetching footer data:', error);
            }
        };

        fetchData();
        fetchFooterMenu();
    }, []);
    return (
        <>
            {footerdata ? (
                <footer className="col-md-12" >
                    <div className="container" >
                        <div className="row" >
                            <div className="col-lg-4" >
                                <Link to={`/`} className="footer-logo" >
                                    <img src={footerdata.logo} className="img-fluid mb-lg-5 mb-3" alt="logo" width="130" />
                                </Link >
                                <div dangerouslySetInnerHTML={{ __html: footerdata.left_info }}></div>
                                <div dangerouslySetInnerHTML={{ __html: footerdata.app_store_logo }}></div>
                            </div>
                            <div className="col-lg-6 offset-lg-2" >
                                {/* <div className="footer-info" dangerouslySetInnerHTML={{ __html: footerdata.right_info }}>

                                </div>
                                <div className="footer-info">
                                    <div className="email-bar" >
                                        <input type="text" name="Email" placeholder="Email address" className="form-control" />
                                        <i className="fa-solid fa-arrow-right" ></i >
                                    </div>
                                </div> */}
                                <div className="row" >
                                    {footerMenu.map((item, index) => (
                                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6" key={index}>
                                            <h5>{item.name}</h5>
                                            <ul>
                                                {item.submenu.map((subitem, subindex) => (
                                                    <li key={subindex}>
                                                        <Link to={subitem.slug}>{subitem.name}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                            {/* {index === 2 && footerMenu.length > 3 && (
                                                <>
                                                    <h5>{footerMenu[3].name}</h5>
                                                    <ul>
                                                        {footerMenu[3].submenu.map((subitem, subindex) => (
                                                            <li key={subindex}>
                                                                <Link to={subitem.url}>{subitem.name}</Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )} */}
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                        <div className="row line-gap" >
                            <hr />
                            <div className="col-md-12" >
                                <div className="Copyright" >
                                    <p>Copyright © 2024 <Link to="/">Skillmotion.ai</Link>. All Rights Reserved.</p>
                                    <ul>
                                        {/* <li>
                                            <Link to="#"><i className="fa-brands fa-twitter"></i></Link>
                                        </li> */}
                                        <li>
                                            <Link to="https://www.facebook.com/skillmotionai" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook"></i></Link>
                                        </li >
                                        <li>
                                            <Link to="https://www.linkedin.com/company/skillmotionai/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></Link>
                                        </li >
                                        <li>
                                            <Link to={'https://www.instagram.com/skillmotion.ai/?hl=en'} target="_blank" rel="noreferrer"><i className="fa-brands fa-instagram"></i></Link>
                                        </li>
                                    </ul >
                                </div>
                            </div>
                        </div>
                    </div>
                    <SocialShare url={window.location} />
                </footer >
            ) :
                (
                    <div></div>
                )
            }
        </>
    );
}

export default Footer;
