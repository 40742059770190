// SkillChart.js

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SkillChart = ({ chart_label, color, barData }) => {
  const data = {
    labels: barData.map(item => item.name),
    datasets: [
      {
        label: chart_label,
        backgroundColor: color,
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.4)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: barData.map(item => item.value),
      },
    ],
  };

  const options = {
    responsive: true,
    indexAxis: 'y', // Flip the axes to display horizontal bars
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `${chart_label}`,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        ticks: {
          autoSkip: false, // Ensure all labels are displayed
          font: {
            size: 12, // Adjust font size if needed
          },
          padding: 10, // Add padding for better readability
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default SkillChart;
