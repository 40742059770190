import React, { useEffect, useState } from "react";
import { Card, Col, Image, Row, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import StarIcon from "../../assets/images/star-icon.png";
import { useSelector } from "react-redux";
import {
  getCoreMap,
  getCoreSingleMap,
  getTecMap,
  getTecSingleMap,
  removeDuplicateSkills,
  uniqueSkillName,
} from "../../dashboard/utils";

const proficiencyCustomTooltip = () => {
  return (
    <>
      <div className="custom-toolip">
        <div className="tooltip-desc mb-2">
          <h2 className="d-flex align-items-center">
            Core Skills
            <span className="ms-3 skill-view-field d-flex align-items-center">
              <span className="skill-box advance">B</span>
              <span className="skill-box advance">I</span>
              <span className="skill-box Intermediate">A</span>
            </span>
          </h2>
          <p>
            Measured on a scale of <b>B</b>asic, <b>I</b>ntermediate, and{" "}
            <b>A</b>dvanced, indicating the level of understanding and ability
            to apply these core skills.
          </p>
        </div>
        <div className="tooltip-desc  mb-2">
          <h2 className="d-flex align-items-center">
            Technical Skills
            <span className="ms-3 skill-view-field d-flex align-items-center">
              <span className="skill-box fill-skill">1</span>
              <span className="skill-box fill-skill">2</span>
              <span className="skill-box fill-skill">3</span>
              <span className="skill-box progress-skill">4</span>
              <span className="skill-box progress-skill">5</span>
              <span className="skill-box progress-skill">6</span>
            </span>
          </h2>
          <p>
            Measured on a scale of 1 to 6. Higher numbers represent a greater
            level of proficiency and expertise.
          </p>
        </div>
        <div className="tooltip-Proficiency">
          <div className="Proficiency-lavel">
            <div className="prf-level">
              <span className="current-level"></span>
              <span>Current Proficiency</span>
            </div>
            <div className="prf-level">
              <span className="Expected-level"></span>
              <span>Expected Proficiency</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const UserSkill = ({
  existingSkills,
  userAllPreDetils,
  updateCurrentSkill,
}) => {
  let isBeta = localStorage.getItem("is_beta");
  const [currentSkills, setCurrentSkills] = useState([]);
  const [skillGaps, setSkillGaps] = useState([]);
  useEffect(() => {
    let CoreSkills = [];
    let TechnicalSkills = [];

    if (userAllPreDetils?.length > 0 && updateCurrentSkill) {
      userAllPreDetils.forEach((it) => {
        const skill = {
          skillName: it?.skill,
          skillProficiencies: it?.proficiency,
        };
        if (it?.type === "ccs") {
          CoreSkills.push(skill);
        } else {
          TechnicalSkills.push(skill);
        }
      });
    }

    if (existingSkills && Array.isArray(existingSkills[0])) {
      setCurrentSkills(
        updateSkills(existingSkills[0][0], CoreSkills, TechnicalSkills)
      );
    } else {
      setCurrentSkills(
        updateSkills(existingSkills[0], CoreSkills, TechnicalSkills)
      );
    }
    if (existingSkills && Array.isArray(existingSkills[1])) {
      setSkillGaps(existingSkills[1][0]);
    } else {
      setSkillGaps(existingSkills[1]);
    }
    console.log(
      existingSkills,
      userAllPreDetils,
      updateCurrentSkill,
      "existingSkills, userAllPreDetils,updateCurrentSkill"
    );
  }, [existingSkills, userAllPreDetils, updateCurrentSkill]);

  // const addNewSkills = (skills, core, tech) => {
  //   const data = skills.map((it) => {
  //     let skill = { skillName: it?.skill, skillProficiencies: it?.proficiency };
  //     if (it?.type == "ccs") {
  //       core.push(skill);
  //     } else {
  //       tech.push(skill);
  //     }
  //   });
  // };

  const updateSkills = (skills, CoreSkills, TechnicalSkills) => {
    if (skills) {
      skills.CoreSkills = [...CoreSkills, ...(skills.CoreSkills || [])];
      skills.TechnicalSkills = [
        ...TechnicalSkills,
        ...(skills.TechnicalSkills || []),
      ];
    }

    let uniqueSkills = {
      CoreSkills: removeDuplicateSkills(skills?.CoreSkills),
      TechnicalSkills: removeDuplicateSkills(skills?.TechnicalSkills),
    };

    return uniqueSkills;
  };

  const { gSkillDes } = useSelector((state) => state?.counterSlice);

  const getSkillDes = (name) => {
    if (name) {
      const des = gSkillDes?.filter(
        (it) => it?.name?.toLowerCase() == name?.toLowerCase()
      );
      if (des?.length > 0) {
        return des?.[0]?.description || name;
      }
    }

    return "";
  };

  const getCoreSkillMap = (skill, type) => {
    if (type === "ccs") {
      let coreSkills = currentSkills?.CoreSkills;
      let skillProf = coreSkills?.find(
        (it) => it?.skillName?.toLowerCase() === skill?.skillName?.toLowerCase()
      )?.skillProficiencies;

      return skillProf;
    } else {
      let techSkills = currentSkills?.TechnicalSkills;
      let skillProf = techSkills?.find(
        (it) => it?.skillName === skill?.skillName
      )?.skillProficiencies;
      return skillProf;
    }
  };

  return (
    <div className="current-skill">
      <Row>
        <Col span={24}>
          <Card>
            <div className="current-skill-box">
              {/* <div className="timelineInfo">
                <div className="skill_info">
                  <h2 className="data-list bhariFont">Your Current Skills</h2>
                  <h2 className="bhariFont subtitle d-flex align-items-center">
                    Proficiencies
                    <span className="ps-2 d-flex align-items-center">
                      {" "}
                      <Tooltip
                        title={proficiencyCustomTooltip}
                        placement="bottomRight"
                        overlayClassName="custom-toolip-bar"
                      >
                        <InfoCircleFilled
                          style={{ fontSize: "20px", color: "#B4B4B4" }}
                        />
                      </Tooltip>
                    </span>
                  </h2>
                </div>
                <div className="skill-list core-skill-list mb-4">
                  {currentSkills?.CoreSkills &&
                    currentSkills?.CoreSkills?.length > 0 && (
                      <>
                        <h3 className="h3-subtitle d-flex align-items-center">
                          Core Skills{" "}
                          <span className="px-2 d-flex align-items-center">
                            {" "}
                            <Tooltip
                              title="Core skills comprises fundamental abilities crucial for success in any role, enabling effective performance and adaptability."
                              placement="right"
                            >
                              <InfoCircleFilled
                                style={{ fontSize: "20px", color: "#B4B4B4" }}
                              />
                            </Tooltip>
                          </span>
                        </h3>
                        <ul className="current-skill-list">
                          {currentSkills?.CoreSkills?.map((item, index) =>
                            item.skillProficiencies ? (
                              <li key={index}>
                                {console.log(
                                  item,
                                  getCoreSkillMap(item, "ccs"),
                                  "currentSkills allProfSkills"
                                )}
                                <p
                                  className="data-list"
                                  style={{ cursor: "default" }}
                                >
                                  <Tooltip
                                    title={getSkillDes(item?.skillName)}
                                    key={index}
                                  >
                                    {index + 1}. {item?.skillName}
                                  </Tooltip>
                                </p>
                                <p className="value-progress-bar">
                                  <span className="skill-view-field d-flex align-items-center">
                                    {getCoreMap(
                                      item?.skillProficiencies || "",
                                      getCoreSkillMap(item, "ccs")
                                    )}
                                  </span>
                                </p>
                              </li>
                            ) : (
                              <></>
                            )
                          )}
                        </ul>
                      </>
                    )}
                </div>
                <div className="skill-list technical-skill-list mb-4">
                  {currentSkills?.TechnicalSkills &&
                    currentSkills?.TechnicalSkills?.length > 0 && (
                      <>
                        <h3 className="h3-subtitle d-flex align-items-center">
                          Technical Skills{" "}
                          <span className="px-2 d-flex align-items-center">
                            {" "}
                            <Tooltip
                              title="Technical skills encompasses job-specific skills essential for a particular role or industry, providing specialized knowledge and expertise."
                              placement="right"
                            >
                              <InfoCircleFilled
                                style={{ fontSize: "20px", color: "#B4B4B4" }}
                              />
                            </Tooltip>
                          </span>
                        </h3>
                        <ul className="current-skill-list">
                          {currentSkills?.TechnicalSkills?.map((item, index) =>
                            item.skillProficiencies ? (
                              <li key={index}>
                                <p
                                  className="data-list"
                                  style={{ cursor: "default" }}
                                >
                                  <Tooltip
                                    title={getSkillDes(item?.skillName)}
                                    key={index}
                                  >
                                    {index + 1}. {item?.skillName}
                                  </Tooltip>
                                </p>
                                <p  className="value-progress-bar">
                                  <span className="skill-view-field d-flex align-items-center">
                                    {getTecMap(
                                      item?.skillProficiencies || 0,
                                      getCoreSkillMap(item, "tsc")
                                    )}
                                  </span>
                                </p>
                              </li>
                            ) : (
                              <></>
                            )
                          )}
                        </ul>
                      </>
                    )}
                </div>
              </div> */}

              <div className="timelineInfo">
                <div className="skill_info">
                  <h2 className="data-list bhariFont d-flex align-items-center">
                    Skill Gap Analysis
                    {isBeta && (
                      <span className="ms-2 d-flex align-items-center">
                        <span>
                          <img src={StarIcon} alt="icon" />
                        </span>{" "}
                        <span className="px-2 d-flex align-items-center">
                          {" "}
                          <Tooltip
                            title="Validation by domain expert in progress"
                            placement="right"
                          >
                            <InfoCircleFilled
                              style={{ fontSize: "20px", color: "#B4B4B4" }}
                            />
                          </Tooltip>
                        </span>
                      </span>
                    )}
                  </h2>
                  <div className="user-current-title">
                    <h2 className="bhariFont subtitle d-flex align-items-center me-4">
                      Current Proficiency{" "}
                    </h2>
                    <h2 className="bhariFont subtitle d-flex align-items-center">
                      Required Proficiencies{" "}
                      <span className="ps-2 d-flex align-items-center">
                        {" "}
                        <Tooltip
                          title={proficiencyCustomTooltip}
                          placement="bottomRight"
                          overlayClassName="custom-toolip-bar"
                        >
                          <InfoCircleFilled
                            style={{ fontSize: "20px", color: "#B4B4B4" }}
                          />
                        </Tooltip>
                      </span>
                    </h2>
                  </div>
                </div>

                {/* start current  */}

                {skillGaps?.CoreSkills && skillGaps?.CoreSkills?.length > 0 && (
                  <>
                    <div className="skill-list core-skill-list mb-0">
                      <h3 className="h3-subtitle d-flex align-items-center">
                        Core Skills{" "}
                        <span className="px-2 d-flex align-items-center">
                          {" "}
                          <Tooltip
                            title="Core skills comprises fundamental abilities crucial for success in any role, enabling effective performance and adaptability."
                            placement="right"
                          >
                            <InfoCircleFilled
                              style={{ fontSize: "20px", color: "#B4B4B4" }}
                            />
                          </Tooltip>
                        </span>
                      </h3>
                      <ul className="current-skill-list">
                        {skillGaps?.CoreSkills?.map((item, index) =>
                          item.skillProficiencies ? (
                            <li key={index}>
                              <p
                                className="data-list"
                                style={{ cursor: "default" }}
                              >
                                <Tooltip
                                  title={getSkillDes(item?.skillName)}
                                  key={index}
                                >
                                  {index + 1}. {item?.skillName}
                                </Tooltip>
                              </p>
                              <div className="progress__value">
                                <p className="value-progress-bar">
                                  <span className="skill-view-field d-flex align-items-center">
                                    {getCoreSingleMap(
                                      getCoreSkillMap(item, "ccs") || "",
                                      false
                                    )}
                                  </span>
                                </p>
                                <p className="value-progress-bar">
                                  <span className="skill-view-field d-flex align-items-center">
                                    {/* {getCoreMap(
                                    item?.skillProficiencies || "",
                                    getCoreSkillMap(item, "ccs")
                                  )} */}
                                    {getCoreSingleMap(
                                      item?.skillProficiencies || "",
                                      true
                                    )}
                                  </span>
                                </p>
                              </div>
                            </li>
                          ) : (
                            <></>
                          )
                        )}
                      </ul>
                    </div>
                  </>
                )}

                {skillGaps?.TechnicalSkills &&
                  skillGaps?.TechnicalSkills?.length > 0 && (
                    <>
                      <div className="skill-list technical-skill-list mt-4">
                        <h3 className="h3-subtitle d-flex align-items-center">
                          Technical Skills{" "}
                          <span className="px-2 d-flex align-items-center">
                            {" "}
                            <Tooltip
                              title="Technical skills encompasses job-specific skills essential for a particular role or industry, providing specialized knowledge and expertise."
                              placement="right"
                            >
                              <InfoCircleFilled
                                style={{ fontSize: "20px", color: "#B4B4B4" }}
                              />
                            </Tooltip>
                          </span>
                        </h3>
                        <ul className="current-skill-list">
                          {skillGaps?.TechnicalSkills?.map((item, index) =>
                            item.skillProficiencies ? (
                              <li key={index}>
                                <p
                                  className="data-list"
                                  style={{ cursor: "default" }}
                                >
                                  <Tooltip
                                    title={getSkillDes(item?.skillName)}
                                    key={index}
                                  >
                                    {index + 1}. {item?.skillName}
                                  </Tooltip>
                                </p>
                                <div className="progress__value">
                                  <p className="value-progress-bar">
                                    <span className="skill-view-field d-flex align-items-center">
                                      {/* {getTecMap(
                                      item?.skillProficiencies || 0,
                                      getCoreSkillMap(item, "tsc")
                                    )} */}
                                      {getTecSingleMap(
                                        getCoreSkillMap(item, "tsc") || 0,
                                        false
                                      )}
                                    </span>
                                  </p>
                                  <p className="value-progress-bar">
                                    <span className="skill-view-field d-flex align-items-center">
                                      {getTecSingleMap(
                                        item?.skillProficiencies || 0,
                                        true
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </li>
                            ) : (
                              <></>
                            )
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                {/* end current  */}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default UserSkill;
