const loadGoogleTranslate = () => {
  // Remove old script if it exists
  const oldScript = document.getElementById("google-translate-script");
  if (oldScript) {
    oldScript.remove();
  }

  // Create new script element
  const script = document.createElement("script");
  script.id = "google-translate-script";
  script.src =
    "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);

  // Define the callback function
  window.googleTranslateElementInit = () => {
    try {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          includedLanguages:
            "ta,te,hi,kn,mr,bn,ar,en,zh-CN,zh-TW,nl,fr,de,id,ja,ko,ms,mn,pl,pt,es,th,vi,sw",
          gaTrack: true,
          gaId: "UA-XXXXXXXX-X",
          autoDisplay: true,
        },
        "google_translate_element"
      );
    } catch (error) {
      console.error("Error initializing Google Translate:", error);
    }
  };

  // Handle script load errors
  script.onerror = () => {
    console.error("Error loading Google Translate script.");
  };
};

export default loadGoogleTranslate;
