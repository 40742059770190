// src/features/counterSlice.js

import { createSlice } from "@reduxjs/toolkit";

export const domainSlice = createSlice({
  name: "domain_expert",
  initialState: {
    tabStatus: "list",
    selectedDomain: "",
    getList:true,
    allJobRole: [],
    roleCount: { total: 0, certified: 0, non_certified: 0 },
    selectedValues: {
      type: "",
      name: "",
      role: "",
      level: "",
    },
    allQuestion:[],
    selectedQuestion:[],
    skillOptions:[],
    totalCount:0,
    userDomains: [],
  },
  reducers: {
    storeAllDomains: (state, action) => {
      if (action?.payload?.type === "role") {
        state.allJobRole = action?.payload?.data;
      }else if (action?.payload?.type === "question") {
        state.allQuestion = action?.payload?.data;
      }
    },
    storeTestStatus: (state, action) => {
      state.tabStatus = action?.payload;
    },
    storeSelectedDomain: (state, action) => {
      state.selectedDomain = action?.payload;
    },
    storeRoleCount: (state, action) => {
      state.roleCount = action?.payload;
    },
    storeSelValues: (state, action) => {
      if(action?.payload?.type==="all"){
        return  {
          type: "",
          name: "",
          role: "",
          level: "",
        };
      }else{
        return {
          ...state,
          selectedValues: {
            ...state.selectedValues,
            [action.payload.type]: action.payload.value,
          },
        };
      }
    },
    storeSkillOptions: (state, action) => {
      state.skillOptions = action?.payload;
    },
    storeTotalCount: (state, action) => {
      state.totalCount = action?.payload;
    },
    storeGetList: (state, action) => {
      state.getList = action?.payload;
    },
    storeUserDomains: (state, action) => {
      state.userDomains = action?.payload;
    },
  },
});

export const {
  storeAllDomains,
  storeSelectedDomain,
  storeRoleCount,
  storeTestStatus,
  storeSelValues,
  storeSkillOptions,
  storeTotalCount,
  storeGetList,
  storeUserDomains
} = domainSlice.actions;

// export const selectCount = (state) => state.counter.value;

export default domainSlice.reducer;
