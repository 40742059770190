import { Button } from "antd";
import QuestionAllList from "./question-all-list";
import SkillDropDown from "./skill-dropdown";
import { callGetApi, callPostApi } from "../../../../services/roadmap_api";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/loader";
import { useEffect, useState } from "react";
import {
  storeAllDomains,
  storeTotalCount,
} from "../../../../store/domainExpert";
import { toast } from "react-toastify";
import DashboardHead from "../../common/dashboard-head";
import { toMessage } from "../../../utils";

const QuestionList = () => {
  const dispatch = useDispatch();
  const {
    selectedValues,
    skillOptions,
    allQuestion,
    selectedDomain,
    totalCount,
  } = useSelector((state) => state?.domainExpert);
  const [loadingFor, setLoadingFor] = useState("");
  console.log(selectedValues, allQuestion, "selectedValues");

  const generateQueAns = async () => {
    if (!selectedValues?.name) {
      toMessage("Please select a skill name to proceed.", "error");
      return;
    }
    if (!selectedValues?.level) {
      toMessage("Please select a skill proficiency level to proceed.", "error");
      return;
    }
    setLoadingFor("Generating test-MCQs...");
    let payload={
      skill_name: selectedValues?.name || "",
      skill_type: selectedValues?.type == "CoreSkills" ? "core" : "technical",
      proficiency_level : selectedValues?.level,
      target_role_name : selectedValues?.role?.label
    }
    // ?skill_name=${selectedValues?.name}&skill_type=${
    //   selectedValues?.type == "CoreSkills" ? "core" : "technical"
    // }&proficiency_level=${selectedValues?.level}
    const res = await callPostApi(`/api/test-MCQs/generate/`,payload);
    if (res && res !== undefined) {
      dispatch(storeAllDomains({ type: "question", data: res }));
      setLoadingFor("");
      return true
    } else {
      dispatch(storeAllDomains({ type: "question", data: [] }));
      toMessage("Something went wrong! try again later", "error");
      setLoadingFor("");
      return false
    }
  };

  const getAllQuestionCount = async () => {
    const res = await callGetApi(
      `/api/domain-expert/question_count/?skill_name=${
        selectedValues?.name
      }&skill_type=${
        selectedValues?.type == "CoreSkills" ? "ccs" : "tsc"
      }&proficiency=${selectedValues?.level}&target_role_name=${encodeURIComponent(selectedValues?.role?.label)}`
    );
    console.log(res, "res");
    if (res && res !== undefined) {
      let count = res?.skill_question_data?.question_count;
      dispatch(storeTotalCount(count));
    }
  };
  console.log(selectedValues,"selectedValues")
  useEffect(() => {
    if (selectedValues?.level && selectedValues?.name && selectedValues?.type) {
      getAllQuestionCount();
    }else{
      dispatch(storeTotalCount(0));
    }
  }, [selectedValues,allQuestion]);

  return (
    <>
      {loadingFor !== "" ? <Loader text={loadingFor} /> : ""}
      <div className="d-flex justify-content-between align-items-center managment-head">
        <DashboardHead
          pageTitle="Questions Management"
          subPageTitle="Select the questions for the selected set of parameters and save them in the Database."
        />
        <Button className="primary-btn" onClick={generateQueAns}>
          Generate More Questions
        </Button>
      </div>
      <SkillDropDown
        selectedValues={selectedValues}
        skillOptions={skillOptions}
      />
      <QuestionAllList
        selectedValues={selectedValues}
        allQuestion={allQuestion}
        generateQueAns={generateQueAns}
        sector={selectedDomain}
        count={totalCount}
      />
    </>
  );
};
export default QuestionList;
