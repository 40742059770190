import React, { useState, useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Nav, Dropdown, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import loadGoogleTranslate from "../../components/loadGoogleTranslate";
import ConfirmModal from "../component/ConfirmModal";
import blankIcon from "../../assets/images/renewal-blank-icon.png";

const Header = ({ profileImage, setProfileImage, showSidebarmenu }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notification_count, setNotification_count] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const toggleNotification = () => {
    setShowNotification(!showNotification);
  };

  const [showmessage, setShowmessage] = useState(false);

  const togglemessage = () => {
    setShowmessage(!showmessage);
  };

  const [showcalendar, setShowcalendar] = useState(false);

  const togglecalendar = () => {
    setShowcalendar(!showcalendar);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  // notification//

  // calender//

  const [date, setDate] = useState(new Date());

  const onChange = (newDate) => {
    setDate(newDate);
  };
  //mobile toggle
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);
  const toggleMobileSearch = () => {
    setIsMobileSearchVisible(!isMobileSearchVisible);
  };
  const closeMobileSearch = () => {
    setIsMobileSearchVisible(false);
  };
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState("");
  const [mentorimg, setMentorimg] = useState("");
  const [notifications, setNotifications] = useState([]);

  const [chatLink, setChatLink] = useState("");
  const [calendarLink, setCalendarLink] = useState("");
  const [showredirects, setShowredirects] = useState(false);

  const [defaultLanguage, setDefaultLanguage] = useState("en");

  const getNotifications = async () => {
    const jsonData = localStorage.getItem("user");
    try {
      if (jsonData) {
        const userdata = JSON.parse(jsonData);
        const response = await fetch(
          `${BASE_URL}/api/get-unread-notifications/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${userdata.token}`,
            },
            body: JSON.stringify({
              id: userdata.id,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setNotifications(data.notifications);
          setNotification_count(data.notifications_count);
        } else {
          // nothing to do here
        }
      }
    } catch (error) {}
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleConfirm = async () => {
    handleClose();

    const jsonData = localStorage.getItem("user");
    try {
      if (jsonData) {
        const userdata = JSON.parse(jsonData);
        const response = await fetch(
          `${BASE_URL}/api/mark-all-notification-read/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${userdata.token}`,
            },
            body: JSON.stringify({
              id: userdata.id,
            }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setNotifications(data.notifications);
          setNotification_count(data.notifications_count);
        } else {
          console.error("Failed to mark all notifications as read");
        }
      }
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  useEffect(() => {
    const jsonData = localStorage.getItem("user");
    getNotifications();

    if (jsonData) {
      try {
        const userdata = JSON.parse(jsonData);

        if (userdata.type == "2" || userdata.type == "5") {
          // if admin or program userdata send to old admin
          window.location.href = `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${userdata.type}`;
          return;
        }
        setName(userdata.name);
        setMentorimg(userdata.avatar);
        setCompanies(userdata.companies);

        if (
          userdata.user_type !== "Skill Manager" &&
          userdata.user_type !== "Domain Expert"
        ) {
          setShowredirects(true);
        } else {
          setShowredirects(false);
        }

        setChatLink(
          `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${userdata.type}&gotopage=chat`
        );
        setCalendarLink(
          `${BASE_URL}/auto-login/?access=${userdata.token}&company=${userdata.company}&type=${userdata.type}&gotopage=calendar`
        );

        const intervalId = setInterval(() => {
          //  refreshToken(token);
        }, 20 * 60 * 1000);
      } catch (error) {
        console.error("Error parsing JSON data:", error);
      }
    } else {
      navigate("/login");
    }
    loadGoogleTranslate();
  }, []);

  const refreshToken = async () => {
    try {
      // Make a request to refresh the token
      const jsonData = localStorage.getItem("user");
      let userdata = JSON.parse(jsonData);
      if (jsonData) {
        if (token) {
          const response = await fetch(`${BASE_URL}/api/refresh-token/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${userdata.token}`,
            },
            body: JSON.stringify({
              token: userdata.token,
              compnay: userdata.compnay,
              type: userdata.type,
            }),
          });

          if (response.ok) {
            // Token refreshed successfully, update access token in state
            const data = await response.json();
            console.log("refreshdata", data);
            localStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(data.data));
          } else {
            // Handle token refresh failure
            console.error("Token refresh failed:", response.statusText);
            // Redirect to login page or perform other actions
          }
        }
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      // Handle token refresh failure
    }
  };

  const gotoCalendar = () => {
    const jsonData = localStorage.getItem("user");
    if (jsonData) {
      let data;
      try {
        data = JSON.parse(jsonData);
        window.location.href = `${BASE_URL}/auto-login/?access=${data.token}&company=${data.company}&type=${data.type}&gotopage=calendar`;
      } catch (error) {
        console.error("Error fetching LeaderBoard data:", error);
      }
    }
  };

  return (
    <>
      <div className="SKD-header">
        <div className="SKD-search">
          <div className="input-group dashtop-search">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              className="form-control"
              placeholder="Search for anything....."
            />
          </div>
          <div className="search-mobile" onClick={toggleMobileSearch}>
            <span className="menu-search">
              <i className="fa fa-search"></i>
            </span>
          </div>
          {isMobileSearchVisible && (
            <div>
              <div id="overlay" onClick={closeMobileSearch}></div>
              <div id="mobile-search">
                <form>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      aria-label="Search..."
                      aria-describedby="search"
                    />
                    <button className="btn" type="button" id="search">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        <div className="profile-notification">
          <div className="d-flex gap-3">
            <div className="nofication-field-desktop d-flex align-items-center gap-3">
              {showredirects && (
                <>
                  <a
                    className="calendar-notification position-relative"
                    target="_blank"
                    href={calendarLink}
                  >
                    <i className="fa-regular fa-calendar"></i>
                  </a>

                  <a
                    className="message-notification position-relative"
                    target="_blank"
                    href={chatLink}
                  >
                    <i className="fa-regular fa-message"></i>
                  </a>
               

                  <span
                className="bell-notification position-relative"
                onClick={toggleNotification}
              >
                <i
                  className={`fa-regular fa-bell ${
                    notification_count > 0 ? "animate-tada" : ""
                  }`}
                ></i>
                {notification_count > 0 && (
                  <span className="header-badge translate-middle badge rounded-pill bg-danger">
                    {notification_count}
                  </span>
                )}

                {showNotification && (
                  <div
                    className="notification-overlay"
                    onClick={toggleNotification}
                  ></div>
                )}
                {showNotification && (
                  <div className="notification-box" onClick={stopPropagation}>
                    <div className="d-flex justify-content-between align-items-center p-3">
                      <h5>Notification</h5>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="heroXMark"
                        style={{
                          width: "1.8em",
                          height: "1.8em",
                          strokeWidth: "1.5",
                        }}
                        onClick={toggleNotification}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </div>
                    <div className="notification-list">
                      {notification_count > 0 && (
                        <div className="text-end">
                          <span
                            title="Mark Notifications as read"
                            className="btn btn-primary mark-read"
                            onClick={() => {
                              handleShow();
                            }}
                          >
                            <i className="fa fa-check"></i>
                          </span>
                        </div>
                      )}
                      <ul>
                        {notifications?.length > 0 ? (
                          notifications.map((notification, index) => (
                            <li key={index}>
                              <figure>
                                <img
                                  src={profileImage}
                                  alt="admin"
                                  width={50}
                                />
                                <span className="">&nbsp;</span>
                              </figure>
                              <figcaption>
                                <p>
                                  <strong>{notification.actor}</strong>
                                </p>
                                <p>{notification.description}</p>
                                <div className="date-time">
                                  <span>{notification.timestamp}</span>
                                </div>
                              </figcaption>
                            </li>
                          ))
                        ) : (
                          <li className="no-records">
                            <div className="icon-box">
                              <img src={blankIcon} alt="icon" />
                            </div>
                          </li>
                        )}
                      </ul>
                      <div
                        id="global-tab-pane"
                        role="tabpanel"
                        aria-labelledby="global-tab"
                        tabIndex="0"
                        className="tab-pane fade"
                      >
                        <ul>
                          {/* {/ Add your global notification items here /} */}
                        </ul>
                        <div className="notification-empty">
                          {" "}
                          Currently, nothing to report!{" "}
                        </div>
                      </div>
                    </div>
                    <div className="text-end border-top p-3">
                      <button type="button" className="btn btn-sm btn-primary">
                        View All
                      </button>
                    </div>
                  </div>
                )}
                  </span>
                  </>
              )}
            </div>
            <div className="menu-language">
              <div id="google_translate_element"></div>
            </div>
          </div>
          {/* <NavDropdown id="nav-dropdown-dark-example" title="Anima Agrawal">
              <NavDropdown.Item href="#">Profile</NavDropdown.Item>
              <NavDropdown.Item href="#">Leaderboard</NavDropdown.Item>
              <NavDropdown.Item href="#">View Certificate</NavDropdown.Item>
              <NavDropdown.Item href="#">Change Password</NavDropdown.Item>
              <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>          
            </NavDropdown>
            <div className='side-mobile-menu'>
              <Link href='#'><i className="fa-solid fa-bars"></i></Link>
            </div> */}

          <Nav className="profile-pic-menu">
            <Dropdown className="d-flex">
              <Dropdown.Toggle as={Nav.Link} id="navbarDropdown">
                <div className="user-profile-pic">
                  <img src={profileImage} alt={name} />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/dashboard/profile">
                  <i className="fas fa-user"></i> Profile
                </Dropdown.Item>
                {showredirects && (
                  <>
                    <Dropdown.Item as={Link} to="/dashboard/leaderboard">
                      <i className="fa-solid fa-person"></i>Leaderboard
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/dashboard/certificates">
                      <i className="fa fa-certificate"></i>View Certificate
                    </Dropdown.Item>
                  </>
                )}
                
                <Dropdown.Item as={Link} to="/dashboard/change-password">
                  <i className="fa-solid fa-key"></i>Change Password
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to="/logout">
                  <i className="fas fa-sign-out-alt fa-fw"></i> Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <div className="side-mobile-menu">
            <Button className="side-menu-btn" onClick={showSidebarmenu}>
              <MdMenu />
            </Button>
          </div>
        </div>
      </div>
      <div className="mobile-notification-field">
        <div className="profile-notification">
          <div className="d-flex gap-5 ">
            <a
              className="calendar-notification position-relative"
              target="_blank"
              href={calendarLink}
            >
              <i className="fa-regular fa-calendar"></i>
            </a>

            <a
              className="message-notification position-relative"
              target="_blank"
              href={chatLink}
            >
              <i className="fa-regular fa-message"></i>
            </a>

            <span
              className="bell-notification position-relative"
              onClick={toggleNotification}
            >
              <i className="fa-regular fa-bell"></i>
              {showNotification && (
                <div
                  className="notification-overlay"
                  onClick={toggleNotification}
                ></div>
              )}
              {showNotification && (
                <div className="notification-box" onClick={stopPropagation}>
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <h5>Notification</h5>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="heroXMark"
                      style={{
                        width: "1.8em",
                        height: "1.8em",
                        strokeWidth: "1.5",
                      }}
                      onClick={toggleNotification}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </div>
                  <div className="notification-list">
                    <ul>
                      {notifications?.length > 0 ? (
                        notifications.map((notification, index) => (
                          <li key={index}>
                            <figure>
                              <img src={profileImage} alt="admin" width={50} />
                              <span className="">&nbsp;</span>
                            </figure>
                            <figcaption>
                              <p>
                                <strong>{notification.actor}</strong>
                              </p>
                              <p>{notification.description}</p>
                              <div className="date-time">
                                <span>{notification.timestamp}</span>
                              </div>
                            </figcaption>
                          </li>
                        ))
                      ) : (
                        <li className="no-records">
                          No notifications available
                        </li>
                      )}
                    </ul>
                    <div
                      id="global-tab-pane"
                      role="tabpanel"
                      aria-labelledby="global-tab"
                      tabIndex="0"
                      className="tab-pane fade"
                    >
                      <ul>
                        {/* {/ Add your global notification items here /} */}
                      </ul>
                      <div className="notification-empty">
                        {" "}
                        Currently, nothing to report!{" "}
                      </div>
                    </div>
                  </div>
                  <div className="text-end border-top p-3">
                    <button type="button" className="btn btn-sm btn-primary">
                      View All
                    </button>
                  </div>
                </div>
              )}
            </span>
          </div>
        </div>
      </div>

      <ConfirmModal
        show={showModal}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default Header;
