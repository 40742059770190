import UserGoalProfile from "../../components/UserGoalProfile";

const TalentList = () => {
    return (
        <>
          {/* <h2 className="layout-heading mb-4">Generate Roadmap</h2> */}
          <UserGoalProfile />
        </>
      );
};
export default TalentList;
