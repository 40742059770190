import React, { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import deleteIcon from "../assets/svg/delete.svg";
const { Dragger } = Upload;
const UploadCv = ({ setLinkorfile, setNextDisabled }) => {
  const [error, setError] = useState(false);
  useEffect(() => {
    console.log("error", error);
  }, [error]);

  const beforeUpload = (file) => {
    if (!file.name.toLowerCase().endsWith(".pdf")) {
      setError(true);
      return Upload.LIST_IGNORE;
    }
    setError(false);
    return true;
  };
  
  const props = {
    name: "file",
    multiple: false,
    accept: ".pdf",
    maxCount: 1,
    beforeUpload: () => false,
    onChange(info,i) {
      if (info.file.status === "removed") {
        setError(false);
        setLinkorfile(null);
        setNextDisabled(true);
        return; 
      }
      if (info.file.name.toLowerCase().endsWith(".pdf")) {
        setError(false);
        setLinkorfile(info.file);
        setNextDisabled(false);
      } else if (
        info.file.status === "done" ||
        info.file.status === "error" ||
        info.file.status === "removed"
      ) {
        setError(false);
        setLinkorfile(null);
        setNextDisabled(true);
      } else if (!info.file.name.toLowerCase().endsWith(".pdf")) {
        setNextDisabled(true);
        setError(true);
        setLinkorfile(null);
      } else {
        setLinkorfile(info.file);
        setNextDisabled(false);
      }
    },
    showUploadList: {
        showUploadIcon:false,
      showRemoveIcon: true,
      removeIcon: <img src={deleteIcon} className="delete_icon" />,
    },
    onRemove: (file) => {
      setError(false);
      console.log("File removed:", file);
      setLinkorfile(null);
      setNextDisabled(true);
    },
  };
  return (
    <>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from
          uploading company data or other banned files.
        </p>
      </Dragger>
      {error ? (
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          <div
            id="certificates_help"
            className="ant-form-item-explain ant-form-item-explain-connected css-dev-only-do-not-override-1xg9z9n"
            role="alert"
          >
            <div className="ant-form-item-explain-error CRed">
              Please add PDF Files Only!
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default UploadCv;
