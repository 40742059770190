import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function Layout({ isLoggedIn, setIsLoggedIn }) {
     return (
        <div>
            <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            <main>
                <Outlet />    
            </main>
            <Footer />
        </div>
    );
}

export default Layout;
