import { Button, Modal } from "antd";
import React, { useState } from "react";

const ResetModalCon = ({ showresetpopup, setShowresetpopup, setClearform }) => {
  const handleModalOk = () => {
    setClearform((prevClearform) => !prevClearform);
    setShowresetpopup(false);
};
const handleModalCancel = () => {
    // console.log(" resset  on cancel --- >");
    setShowresetpopup(false);
};
  return (
    <div>
      <Modal
        title="Reset Confirmation"
        visible={showresetpopup}
        okText={"Confirm"}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        closeIcon={false}
        className="sma-modal"
      >
     <p>Are you sure you want to reset the form?</p>
        <p>This will clear all current data.</p>
      </Modal>
         
    </div>
  );
};

export default ResetModalCon;
