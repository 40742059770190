
import DashboardHead from "../../common/dashboard-head";
import JobListTable from "./job-list-table";
import JobRoleCalculationList from "./job-role-calculation-value";
const DashboardExpert = ({rolesBackup}) => {
  return (
    <>
      <DashboardHead pageTitle="Dashboard" subPageTitle="Domain Expert" showMenu={true}/>
      <JobRoleCalculationList/>
      <JobListTable rolesBackup={rolesBackup} />
    </>
  );
};
export default DashboardExpert;
