import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

export function RadarChart({ countSkills, type }) {
  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );

  const getBasicProf=(l)=>{
    let valNumber=l==0?0:l<2?1:l==2?2:2;
    let val=['0','Basic','Intermediate','Advanced'];
    return val[valNumber];
  }

 

  const getGraphData = (data, type) => {
    const result = {
      labels: data?.filter((it) => it?.type == type)?.map((it) => it?.name) || [],
      datasets: [
        {
          label: "Current Proficiency",
          data: data
            ?.filter((it) => it?.type == type)
            ?.map((it) => it?.currentProf) || [],
          backgroundColor: "rgba(192, 255, 188, 0.5)",
          borderColor: "rgba(10, 165, 1, 1)",
          borderWidth: 1,
        },
        {
          label: "Expected Proficiency",
          data: data
            ?.filter((it) => it?.type == type)
            ?.map((it) => it?.expetedProf) || [],
          backgroundColor: "rgba(0, 0, 255, 0.4)",
          borderColor: "rgba(0, 0, 255, 1)",
          borderWidth: 0.5,
          pointRotation: 3,
          pointBackgroundColor: "rgba(0, 0, 255, 1)",
        },
      ],
    };
  
    return result;
  };
  


  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: function(context) {
            const datasetLabel = context[0].dataset.label;
            if (datasetLabel === "Current Preference") {
              return "Current Proficiency";
            } else if (datasetLabel === "Expected Preference") {
              return "Expected Proficiency";
            }
            return datasetLabel;
          },
          label: function(context) {
            const skillName = context.label;
            const proficiency = context.raw;
            if(context?.dataset?.label=="Current Preference"){
              return `${skillName}: ${type=="tsc"?(proficiency-0.2):(getBasicProf(proficiency-0.1))}`;
            }else{
              return `${skillName}: ${type=="tsc"?(proficiency):(getBasicProf(proficiency))}`;
            }
          }
        }
      },
      legend: {
        position: 'bottom',
        labels: {
          color: '#383838',
          boxWidth: 10,
          boxHeight: 10,
          display: "block",
          borderRadius: 10,
          font: {
            size: 12,
            weight: 400,
            family: `"Spline Sans", sans-serif`
          }
        }
      }
    },
    scales: {
      r: {
        min: 0,
        max: type=="tsc"?6.5:3.5,
        pointLabels: {
          font: {
            size: 10 
          },
          lineHeight:20,
          callback: function (label) {
            return label.length > 15 ? label.substr(0, 15) + '...' : label;
          },
        },
        ticks: {
          stepSize: 1,
          color: '#787486',
          callback: function (value) {
            const labels = type=="tsc"?[0,1,2,3,4,5,6]:["","Basic", "Intermediate", "Advanced"];
            return labels[value] || '';
          },
          font: {
            size: 12,
            weight: 400,
            family: `"Spline Sans", sans-serif`
          }
        },
      }
    }
  };
  
  
  return <Radar data={getGraphData(countSkills, type)} options={options}/>;
}
