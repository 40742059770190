import LayoutHeading from "./layout-head";
import RenewalIcon from "../../../assets/images/renewal-blank-icon.png";
import { Button } from "react-bootstrap";
const RenewalStartUp = ({ showModal }) => {
  return (
    <>
      <LayoutHeading pageTitle="Skill Renewal" />
      <div className="subtitle">
        <b>Note:</b> Did you know? Take skill tests without a roadmap! Assess
        your skills and proficiency levels in various job roles.
      </div>
      <div className="blank-layout ">
        <div className="icon-box">
          <img src={RenewalIcon} alt="icon" />
        </div>
        <Button className="primary-btn" onClick={showModal}>
          Explore Skill Tests
        </Button>
      </div>
    </>
  );
};
export default RenewalStartUp;
