import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import Sidebar from "./sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import "../../assets/css/dashboard-style.css";
import { useSelector } from "react-redux";
//import Sidebar from './common/sidebar';

const DashboardRoutes = ({
  current_company,
  setCurrent_company,
  profileImage,
  setProfileImage,
  userType
}) => {
  const navigate = useNavigate();

  const [sideBarMenu, setSideBarMenu] = useState(false);
  const handleShowSidebarMenu = () => {
    setSideBarMenu(true);
  };
  const handleHideSidebarMenu = () => {
    setSideBarMenu(false);
  };
  const { isLoggedIn } = useSelector((state) => state?.counterSlice);

  useEffect(() => {
    console.log("isloggedin", isLoggedIn);
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  return (
    <>
      <div className="dashboard">
        {/* <Sidebar /> */}
        <Sidebar
          isLoggedIn={isLoggedIn}
          sideBarMenu={sideBarMenu}
          handleHideSidebarMenu={handleHideSidebarMenu}
          current_company={current_company}
          setCurrent_company={setCurrent_company}
          profileImage={profileImage}
          setProfileImage={setProfileImage}
          isOrganization={window.location.pathname.includes("/organization")}
          userType={userType}
        />
        <div className="dashboard-main">
          <Header
            profileImage={profileImage}
            setProfileImage={setProfileImage}
            showSidebarmenu={handleShowSidebarMenu}
          />
          <div className="main-content">
            {/* Main content */}
            <Outlet></Outlet>
           
          </div>
          <Footer />
          
        </div>
      </div>
      
    </>
  );
};

export default DashboardRoutes;
