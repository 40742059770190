import { useDispatch, useSelector } from "react-redux";
import { storeSelectedDomain } from "../../../store/domainExpert";
import { Select } from "antd";

const JobCustomPlaceholder = (props) => (
  <div className="d-flex align-items-center position-absolute ps-2">
    <span className="ms-2 fs-6">Job Roles</span>
  </div>
);

const DashboardHead = ({ pageTitle, subPageTitle, showMenu }) => {
  const dispatch = useDispatch();

  const { selectedDomain, userDomains } = useSelector(
    (state) => state?.domainExpert
  );

  const handleSelect = (val) => {
    const selected = userDomains?.filter((it) => it?.label == val);
    dispatch(storeSelectedDomain(selected[0]));
  };

  return (
    <>
      <div className="headingbar d-flex justify-content-between">
        <div className="title d-flex justify-content-between align-items-end w-100 mb-4">
          <div>
            <h2>{pageTitle}</h2>
            <p className="subpagetitle mb-0">{subPageTitle}</p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        {showMenu && userDomains.length > 0 && (
          <Select
            value={selectedDomain}
            className="select-option-list"
            classNamePrefix="select"
            options={userDomains}
            components={{
              Placeholder: JobCustomPlaceholder,
            }}           
            dropdownStyle={{ width: 'auto', minWidth: 300 }} // Adjust dropdown width
            onChange={handleSelect}
          />
        )}
      </div>
    </>
  );
};
export default DashboardHead;
