import React from "react";
import { Row, Timeline, Col } from "antd";
const UserRoadmap = ({ skillGapAnalysis, showLinks }) => {
  return (
    <div className="roadmap-skill">
      <Row>
        <Col span={24}>
          <h5 className="roadmap-description"></h5>
          <div className="roadmap-line">
            <Timeline mode="left">
              {Array.isArray(skillGapAnalysis) &&
                skillGapAnalysis?.map((item, index) => {
                  // console.log("inside");
                  const firstKey = Object.keys(item)[0];
                  let displaceKey = "";
                  if (item[firstKey]?.length == 0) {
                    return <></>;
                  }
                  if (
                    firstKey !== "Mentorships" &&
                    firstKey !== "Reminder" &&
                    firstKey !== "Mentor_OG_Images"
                  ) {
                    if (firstKey === "Solidify_Fundamentals") {
                      displaceKey = "Solidify Fundamentals";
                    }
                    if (firstKey === "Expand_Horizons") {
                      displaceKey = "Expand Horizons";
                    }
                    if (firstKey === "Practice_Makes_Perfect") {
                      displaceKey = "Practice Makes Perfect";
                    }
                    if (firstKey === "Certifications") {
                      displaceKey = "Certifications";
                    }
                    {
                      console.log(
                        "skillGapAnalysis",
                        item[firstKey],
                        firstKey,
                        item,
                        skillGapAnalysis
                      );
                    }
                    return (
                      <Timeline.Item color="#3F3EED" key={index}>
                        <h4>
                          {displaceKey.length > 1 ? displaceKey : firstKey}
                        </h4>
                        <ul className="roadmapListing">
                          {firstKey !== "Mentorships" &&
                            typeof firstKey === "string" &&
                            Array.isArray(item[firstKey]) &&
                            item[firstKey].length > 0 &&
                            item[firstKey]?.map((subItem, index) => {
                              return (
                                <li key={index}>
                                  <div className="timelineInfo">
                                    {subItem.Topic ? (
                                      <h5>{subItem.Topic}</h5>
                                    ) : (
                                      <h5>{subItem.topic}</h5>
                                    )}
                                    {subItem.Description ? (
                                      <h6>{subItem.Description}</h6>
                                    ) : (
                                      <h6>{subItem.description}</h6>
                                    )}
                                    {showLinks ? (
                                      <ul>
                                        {subItem.links?.map((item, index) => (
                                          <li key={index}>
                                            <a target="_blank" href={item}>
                                              {item}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : null}
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </Timeline.Item>
                    );
                  }
                })}
            </Timeline>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default UserRoadmap;
