import axios from "axios";
import { allDomain, allTests } from "./testConstant";
import { jobRoleList, renewalData, skillOptionList } from "../constant";
import { toast } from "react-toastify";
import { toMessage } from "../dashboard/utils";

// Define the base URL
// const baseURL = `http://127.0.0.1:8001`;
const baseURL = `https://stageanalyse.skillmotion.ai`;

// Create an instance of Axios with the configured options
const axiosMapInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    // Include the authorization token
    Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3NDkxMTMzMTIsImlhdCI6MTcxODAwOTMxMiwiZGF0YSI6eyJ1c2VyX2lkIjoxMjMsInJvbGUiOiJhZG1pbiJ9fQ.GDMJnWscyweS6ssC3IOkUeBEj3KNZHZnRL-POVwMRKI`,
  },
});

// Request interceptor
axiosMapInstance.interceptors.request.use((config) => {
  return config;
});

// Response interceptor
axiosMapInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const callPostApi = async (url, payload, header) => {
  try {
    const response = await axiosMapInstance.post(url, payload, header);
    if (response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error, "call Post Api error");
    let detail = error?.response?.data;
    if (detail?.detail?.includes("MySQL ")) {
      toMessage("something went wrong ,please try after some time", "error")
    }
    // else if(detail?.detail?.includes("Token has expired")){
    //   localStorage.clear();
    //   window.location.replace('/login');
    // }
  }
};

export const callPutApi = async (url, payload) => {
  try {
    const response = await axiosMapInstance.put(url, payload);
    if (response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    console.error(error, "call put Api error");
    let detail = error?.response?.data;
    if (detail?.detail?.includes("MySQL Connection not")) {
      toMessage("something went wrong ,please try after some time", "error")
    }
    // else if(detail?.detail?.includes("Token has expired")){
    //   localStorage.clear();
    //   window.location.replace('/login');
    // }
  }
};

export const callGetApi = async (url) => {
  try {
    const response = await axiosMapInstance.get(url);
    if (response.status === 200) {
      return response?.data;
    }
    return response;
  } catch (error) {
    console.error(error, "call Get Api error");
    let detail = error?.response?.data;
    if (detail?.detail?.includes("No data found")) {
      return [];
    }
    // else if(detail?.detail?.includes("Token has expired")){
    //   localStorage.clear();
    //   window.location.replace('/login');
    // }
  }
};

export const callGetIp = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response?.data;
  } catch (error) {
    console.error(error, "call Get Api error");
  }
};

export const callGetDomains = async (id) => {
  try {
    const response = await axios.get(`https://dashboard.skillmotion.ai/api/user-domains/${id}/`);

    return response?.data;
  } catch (error) {
    console.error(error, "call Get Api error");
  }
};

export const callTestApi = async (type) => {
  try {
    let res = { status: false, data: "" };
    if (type === "domain") {
      res = { status: true, data: allDomain };
    } else if (type === "proceed") {
      res = { status: true, data: jobRoleList };
    } else if (type === "skills") {
      res = { status: true, data: skillOptionList };
    } else if (type === "alltests") {
      res = { status: true, data: renewalData };
    }
    return res;
  } catch (error) {
    console.error(error, "call Post Api error");
  }
};

// Export the Axios instance
export default axiosMapInstance;
