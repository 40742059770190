import React from "react";
import { saveAs } from "file-saver";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { Button } from "antd";
import SkillLogo from "../../../assets/images/skill-logo.png";
import {
  CloudDownloadOutlined,
  DownloadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Alignment } from "react-data-table-component";
import html2canvas from "html2canvas";
import skillgapimg from "../../../assets/images/reports/skillgapreport.png";

const footerText =
  "Copyright © 2024 - 2024 Skillmotion.ai. All Rights Reserved. To report contact info@skillmotion.ai";

const generatePdf = async (
  chartRef,
  chartRef_ccs,
  skillGapRef,
  currentSkills,
  skillGapAnalysis,
  roadmap,
  mentorships,
  showLinks,
  userName,
  jobRole,
  description
) => {
  const pdfDoc = await PDFDocument.create();
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
  const timesRomanBoldFont = await pdfDoc.embedFont(
    StandardFonts.TimesRomanBold
  );

  const pageWidth = 600;
  const pageHeight = 800;
  const margin = 50;
  const lineHeight = 20;
  const maxWidth = pageWidth - 2 * margin;
  const user = JSON?.parse(localStorage.getItem("user"));

  const drawFooter = (page) => {
    const { width } = page.getSize();
    const textWidth = timesRomanFont.widthOfTextAtSize(footerText, 10);
    const xPosition = (width - textWidth) / 2;

    page.drawText(footerText, {
      x: xPosition,
      y: 20,
      size: 10,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });
  };

  const drawBulletPoint = (page, x, y) => {
    const bulletRadius = 2;
    const bulletAdjustment = 12;
    page.drawCircle({
      x: x,
      y: y - bulletAdjustment,
      size: bulletRadius,
      color: rgb(0, 0, 0),
    });
  };

  const addSectionTitleFirstText = (page, text, yPosition, fontSize) => {
    const textWidth = timesRomanBoldFont.widthOfTextAtSize(text, fontSize);
    const xPosition = (pageWidth - textWidth) / 2;

    page.drawText(text, {
      x: xPosition,
      y: yPosition - 140,
      size: fontSize,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });
  };

  const addSectionText = (
    page,
    text,
    xPosition,
    yPosition,
    fontSize,
    font,
    color
  ) => {
    const availableWidth = page.getWidth() - xPosition - margin;
    page.drawText(text, {
      x: xPosition,
      y: yPosition,
      size: fontSize,
      font: font,
      color: color,
      maxWidth: availableWidth,
      lineHeight: fontSize * 1.2,
      align: "left",
    });
  };

  const addSectionTitleStart = (page, text, yPosition) => {
    page.drawText(text, {
      x: 50,
      y: yPosition - 10,
      size: 20,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
    });
  };

  const addSectionSkillTitle = (page, text, yPosition) => {
    page.drawText(text, {
      x: 50,
      y: yPosition - 10,
      size: 20,
      weight: "bold",
      font: timesRomanFont,
      color: rgb(0, 0, 0),
    });
  };

  const addUserName = (page, text, x, y, size = 18, options = {}) => {
    const textWidth = timesRomanBoldFont.widthOfTextAtSize(text, 160);
    page.drawText(text, {
      x: x,
      y: y - 215,
      size: size,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
      ...options,
    });
    page.drawLine({
      start: { x: 50, y: y - 300 },
      end: { x: 50 + textWidth, y: y - 300 },
      thickness: 1,
      color: rgb(0, 0, 0),
    });
  };
  const wrapTextLink = (text, font, size, maxWidth) => {
    const lines = [];
    let line = "";
    for (let i = 0; i < text.length; i++) {
      const testLine = line + text[i];
      const testWidth = font.widthOfTextAtSize(testLine, size);

      if (testWidth > maxWidth && line !== "") {
        lines.push(line);
        line = text[i];
      } else {
        line = testLine;
      }
    }
    if (line) {
      lines.push(line);
    }
    return lines;
  };
  const wrapText = (text, font, size, maxWidth) => {
    if (typeof text !== "string" || text.trim() === "") {
      return [];
    }

    const words = text.split(" ");
    let lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = font.widthOfTextAtSize(currentLine + " " + word, size);
      if (width < maxWidth) {
        currentLine += " " + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  const addJobTitle = (page, text, x, y, size = 18, options = {}) => {
    const lines = wrapText(text, timesRomanBoldFont, size, maxWidth - x);
    lines.forEach((line) => {
      page.drawText(line, {
        x: x,
        y: y - 220,
        size: size,
        font: timesRomanBoldFont,
        color: rgb(0, 0, 0),
        ...options,
      });
      y -= lineHeight;
    });
  };

  // const addSectionTitle = (page, text, yPosition, fontsize) => {
  //     page.drawText(text, {
  //         x: margin,
  //         y: yPosition - 20,
  //         size: fontsize,
  //         margin: margin,
  //         font: timesRomanBoldFont,
  //         color: rgb(0, 0, 0),
  //     });
  // };

  const addSectionTitle = (
    page,
    text,
    yPosition,
    fontSize,
    font = timesRomanBoldFont
  ) => {
    const availableWidth = page.getWidth() - 2 * margin; // Adjust for margins

    // Split text into lines based on available width
    page.drawText(text, {
      x: margin,
      y: yPosition,
      size: fontSize,
      font: font,
      color: rgb(0, 0, 0),
      maxWidth: availableWidth,
      lineHeight: fontSize * 1.2, // Adjust line height as needed
      align: "left", // or 'center' or 'right'
    });
  };

  const getTextHeight = (text, fontSize, lineHeight, maxWidth, font) => {
    const words = text.split(" ");
    let lines = [];
    let currentLine = "";

    words.forEach((word) => {
      const testLine = currentLine + word + " ";
      const testWidth = pageWidth - 2 * margin;
      if (testWidth > maxWidth) {
        lines.push(currentLine.trim());
        currentLine = word + " ";
      } else {
        currentLine = testLine;
      }
    });
    lines.push(currentLine.trim());

    const numberOfLines = lines.length;
    return numberOfLines * fontSize * lineHeight;
  };

  const addSkillTextBlock = (page, text, x, y, size = 15, options = {}) => {
    page.drawText(text, {
      x: x,
      y: y - 20,
      size: size,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
      ...options,
    });
  };

  const addCoreSkillTextBlock = (page, text, x, y, size = 13, options = {}) => {
    page.drawText(text, {
      x: x,
      y: y - 20,
      size: size,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
      ...options,
    });
  };

  const addTextBlock = (page, text, x, y, size = 12, options = {}) => {
    page.drawText(text, {
      x: x,
      y: y - 20,
      size: size,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
      ...options,
    });
  };

  const addBoldTextBlock = (page, text, x, y, size = 12, options = {}) => {
    page.drawText(text, {
      x: x,
      y: y - 20,
      size: size,
      font: timesRomanBoldFont,
      color: rgb(0, 0, 0),
      ...options,
    });
  };

  const addLinksBlock = (page, text, x, y, size = 12, options = {}) => {
    page.drawText(text, {
      x: x,
      y: y - 20,
      size: size,
      font: timesRomanFont,
      color: rgb(0, 0, 1),
      ...options,
    });
  };

  const addCompasTextBlock = (page, text, x, y, size = 13, options = {}) => {
    page.drawText(text, {
      x: x,
      y: y - 20,
      size: size,
      font: timesRomanFont,
      color: rgb(0, 0, 0),
      ...options,
    });
  };

  const drawVerticalLine = (page, yPosition, height) => {
    const xPosition = page.getWidth() / 2;

    page.drawLine({
      start: { x: xPosition, y: yPosition },
      end: { x: xPosition, y: yPosition - height },
      thickness: 1,
      color: rgb(0, 0, 0),
    });
  };

  const addSkillsWithPagination = (skills, page, yPosition) => {
    skills.forEach((skill) => {
      const lines = wrapText(
        `${skill.skillName}: ${skill.skillProficiencies}`,
        timesRomanFont,
        12,
        maxWidth
      );
      lines.forEach((line) => {
        if (yPosition < margin) {
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          yPosition = pageHeight - margin;
        }
        drawBulletPoint(page, margin - 10, yPosition - 5);
        addCompasTextBlock(page, line, margin, yPosition);
        yPosition -= lineHeight;
      });
    });
    return yPosition;
  };

  const marioUrl = SkillLogo;
  const marioImageBytes = await fetch(marioUrl).then((res) =>
    res.arrayBuffer()
  );
  const marioImage = await pdfDoc.embedPng(marioImageBytes);

  let page = pdfDoc.addPage([pageWidth, pageHeight]);
  let yPosition = pageHeight - margin;

  page.drawImage(marioImage, {
    x: 420,
    y: yPosition - 100,
    width: 150,
    height: 150,
  });

  addSectionTitleFirstText(page, "SkillSphere Report", yPosition, 20);
  yPosition -= 20;

  const skillgapimgBytes = await fetch(skillgapimg).then((res) =>
    res.arrayBuffer()
  );
  const skillimage = await pdfDoc.embedPng(skillgapimgBytes);

  page.drawImage(skillimage, {
    x: margin,
    y: yPosition - 500,
    width: 200,
    height: 238,
  });

  drawVerticalLine(page, yPosition - 200, 348);
  let xPosition = margin + 280;
  const orangeColor = rgb(1, 0.65, 0);
  const defaultColor = rgb(0, 0, 0);

  let specialyPosition = yPosition - 380;
  addSectionText(
    page,
    "Skillsmotion provides tailored advice for professional growth, helping you reach your career objectives Skillmotion.ai",
    xPosition,
    specialyPosition,
    12,
    timesRomanFont,
    rgb(0, 0, 0)
  );

  
  xPosition = margin + 25;
  yPosition -= 520;
  let stext = "Skill Gap Report for Target Role:";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    rgb(0, 0, 0)
  );

  let xtoincrease = timesRomanBoldFont.widthOfTextAtSize(stext, 12) - timesRomanBoldFont.widthOfTextAtSize(jobRole, 12);

  xPosition = margin + 25 + xtoincrease;
  yPosition -= 20;
  addSectionText(
    page,
    jobRole,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    rgb(0, 0, 0)
  );

 
  xtoincrease = timesRomanBoldFont.widthOfTextAtSize(stext, 12) - timesRomanBoldFont.widthOfTextAtSize("powered by Skillsphere@Skillmotion.AI", 8);
  xPosition = margin + 25 + xtoincrease;
  yPosition -= 12;
  addSectionText(
    page,
    "powered by Skillsphere@Skillmotion.AI",
    xPosition,
    yPosition,
    8,
    timesRomanBoldFont,
    rgb(0, 0, 0)
  );

  page = pdfDoc.addPage([pageWidth, pageHeight]);

  xPosition = margin;
  yPosition = pageHeight - margin;
  addSectionText(
    page,
    "Introduction to Skillmotion.AI",
    xPosition,
    yPosition,
    16,
    timesRomanBoldFont,
    defaultColor
  );

  yPosition -= 30;
   stext = "Skillmotion.AI";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );
  xPosition = xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext =
    " is a cutting-edge talent insights and analytics platform designed to revolutionize how";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );
  xPosition = margin;
  yPosition -= 20;
  stext =
    "organizations and individuals identify and bridge skill gaps. By leveraging advanced artificial";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin;
  yPosition -= 20;
  stext = "intelligence and machine learning algorithms – the ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );
  xPosition = xPosition += timesRomanFont.widthOfTextAtSize(stext, 12);
  stext = " Skillsphere ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition = xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext = "Skillmotion.AI provides precise and";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin;
  yPosition -= 20;
  stext =
    "actionable insights into the skills needed to excel in any given role.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin;
  yPosition -= 30;
  stext =
    "Our platform offers personalized recommendations for training, development, and mentorship, ensuring that users can effectively navigate their development and achieve their professional goals.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  page.drawLine({
    start: { x: margin, y: yPosition - 30 },
    end: { x: pageWidth - margin, y: yPosition - 30 },
    thickness: 1,
    color: rgb(0, 0, 0),
  });

  xPosition = margin;
  yPosition -= 60;
  addSectionText(
    page,
    "Methodology",
    xPosition,
    yPosition,
    16,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition = margin;
  yPosition -= 30;
  addSectionText(
    page,
    "The Skillmotion.AI Skill Gap Report utilizes a proprietary methodology combining various data sources, including industry benchmarks, job role requirements, and individual skill assessments. Our AI-driven approach involves the following steps:",
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin + 20;
  yPosition -= 60;
  stext = "1.    ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition += timesRomanFont.widthOfTextAtSize(stext, 12);
  let list_start = xPosition;
  stext = "Role Analysis: ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext = "Detailed examination of the target role's requirements.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin + 20;
  yPosition -= 20;

  stext = "2.    ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition += timesRomanFont.widthOfTextAtSize(stext, 12);
  stext = "Skill Assessment: ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext =
    "Evaluation of the individual's current skill set through assessments and";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = list_start;
  yPosition -= 15;
  stext = "self-reports.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin + 20;
  yPosition -= 20;

  stext = "3.   ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition += timesRomanFont.widthOfTextAtSize(stext, 12);
  stext = "Gap Identification: ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext =
    "Identification of skill gaps by comparing the individual's skills against the";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = list_start;
  yPosition -= 15;
  stext = "target role's requirements.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin + 20;
  yPosition -= 20;

  stext = "4.   ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition += timesRomanFont.widthOfTextAtSize(stext, 12);
  stext = "Recommendation Generation: ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext = "Providing personalized recommendations for training courses";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = list_start;
  yPosition -= 15;
  stext = "and potential mentors.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  yPosition -= 30;
  page.drawLine({
    start: { x: margin, y: yPosition },
    end: { x: pageWidth - margin, y: yPosition },
    thickness: 1,
    color: rgb(0, 0, 0),
  });

  page = pdfDoc.addPage([pageWidth, pageHeight]);
  yPosition = pageHeight - margin;
  xPosition = margin;
  addSectionText(
    page,
    "Executive Summary",
    xPosition,
    yPosition,
    16,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition = margin;
  yPosition -= 20;
  addSectionText(
    page,
    "Target Role: " + jobRole,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  yPosition -= 30;
  stext = "Role Description:";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition = margin;
  stext = description;
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition - 30,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin;
  yPosition -=
    getTextHeight(
      stext,
      12,
      12 * 1.2,
      pageWidth - xPosition - margin,
      timesRomanFont
    );
  
   yPosition -= 30;
  page.drawLine({
    start: { x: margin, y: yPosition },
    end: { x: pageWidth - margin, y: yPosition },
    thickness: 1,
    color: rgb(0, 0, 0),
  });

  yPosition -= 30;
  stext = "How to Read the Skill Gap Report";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  yPosition -= 30;
  stext = "This report is divided into several key sections:";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin + 20;
  yPosition -= 30;
  stext = "1.    ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition += timesRomanFont.widthOfTextAtSize(stext, 12);

  list_start = xPosition;
  
  stext = "Current Skill Snapshot: ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext = "top 5 skill strengths and top 5 gap areas";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin + 20;
  yPosition -= 20;

  stext = "2.    ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition += timesRomanFont.widthOfTextAtSize(stext, 12);
  stext = "Required Skills: ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext = "The essential skills needed for the target role.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin + 20;
  yPosition -= 20;

  stext = "3.   ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition += timesRomanFont.widthOfTextAtSize(stext, 12);
  stext = "Skill Gaps: ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext =
    "The differences between your current skills and the required skills.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin + 20;
  yPosition -= 20;

  stext = "4.   ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition += timesRomanFont.widthOfTextAtSize(stext, 12);
  stext = "Recommendations: ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition += timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext =
    "Suggested courses and potential mentors to help bridge the identified skill";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = list_start;
  yPosition -= 15;
  stext = "gaps.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  yPosition -= 30;
  page.drawLine({
    start: { x: margin, y: yPosition },
    end: { x: pageWidth - margin, y: yPosition },
    thickness: 1,
    color: rgb(0, 0, 0),
  });

  page = pdfDoc.addPage([pageWidth, pageHeight]);

  xPosition = margin;
  yPosition = pageHeight - margin;
  stext = "Current Skill Snapshot";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  yPosition -= 30;
  stext = "Your  main 5 skills and 5 gaps are listed below:";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  const skillGapImage = await html2canvas(skillGapRef.current);
  const skillGapUrl = skillGapImage.toDataURL("image/png");
  const skillGapImageBytes = await fetch(skillGapUrl).then((res) =>
    res.arrayBuffer()
  );
  const skillGapEmbed = await pdfDoc.embedPng(skillGapImageBytes);
  const skillGapDims = skillGapEmbed.scale(0.5);

  let gapimgw = (skillGapDims.width * 70) / 100;
  let gapimgh = (skillGapDims.height * 70) / 100;
  yPosition = yPosition - gapimgh - 30;
  page.drawImage(skillGapEmbed, {
    x: (page.getWidth() - gapimgw) / 2,
    y: yPosition,
    width: gapimgw,
    height: gapimgh,
  });

  // if (userName) {
  //     addUserName(page, userName || "", margin, yPosition);
  //     yPosition -= 20;
  // }

  // addJobTitle(page, jobRole || "", margin, yPosition);
  // yPosition -= 100;

  //   page = pdfDoc.addPage([pageWidth, pageHeight]);
  //   yPosition = pageHeight - margin;

  //   addSectionTitleStart(page, "Skill Compass", yPosition);
  //   yPosition -= lineHeight;

  //   addSkillTextBlock(page, "Your Current Skills", margin, yPosition);
  //   yPosition -= lineHeight;

  //   addCoreSkillTextBlock(page, "Core Skills", margin, yPosition);
  //   yPosition -= lineHeight;
  //   yPosition = addSkillsWithPagination(
  //     currentSkills.CoreSkills,
  //     page,
  //     yPosition
  //   );

  //   yPosition -= lineHeight;
  //   addCoreSkillTextBlock(page, "Technical Skills", margin, yPosition);
  //   yPosition -= lineHeight;
  //   yPosition = addSkillsWithPagination(
  //     currentSkills.TechnicalSkills,
  //     page,
  //     yPosition
  //   );

  //   yPosition -= 2 * lineHeight;
  //   addSkillTextBlock(page, "Skill Gap Analysis", margin, yPosition);
  //   yPosition -= lineHeight;

  //   addCoreSkillTextBlock(page, "Core Skills", margin, yPosition);
  //   yPosition -= lineHeight;
  //   yPosition = addSkillsWithPagination(
  //     skillGapAnalysis.CoreSkills,
  //     page,
  //     yPosition
  //   );

  //   yPosition -= lineHeight;
  //   addCoreSkillTextBlock(page, "Technical Skills", margin, yPosition);
  //   yPosition -= lineHeight;
  //   yPosition = addSkillsWithPagination(
  //     skillGapAnalysis.TechnicalSkills,
  //     page,
  //     yPosition
  //   );
//chartRef_ccs
  const chartImage = await html2canvas(chartRef.current);
  const chartDataUrl = chartImage.toDataURL("image/png");
  const chartImageBytes = await fetch(chartDataUrl).then((res) =>
    res.arrayBuffer()
  );
  const chartImageEmbed = await pdfDoc.embedPng(chartImageBytes);
  const chartImageDims = chartImageEmbed.scale(0.5);

  const chartPage = pdfDoc.addPage([pageWidth, pageHeight]);
  yPosition = pageHeight - margin;
  addSectionTitle(chartPage, "Your Skills Gap Analysis", yPosition, 18);
  yPosition -= 20;
  addSectionTitle(
    chartPage,
    "This section provides a detailed analysis of the Current Skills vs Expected Skills and highlights the Areas for Improvement.",
    yPosition,
    14,
    timesRomanFont
  );
  
  yPosition -= 60;

  let imgw = (chartImageDims.width * 70) / 100;
  let imgh = (chartImageDims.width * 70) / 100;
  chartPage.drawImage(chartImageEmbed, {
    x: (chartPage.getWidth() - imgw) / 2,
    y: chartPage.getHeight() - imgh - 50 - margin,
    width: (chartImageDims.width * 70) / 100,
    height: (chartImageDims.height * 70) / 100,
  });



  const chartImage_ccs = await html2canvas(chartRef_ccs.current);
  const chartDataUrl_ccs = chartImage_ccs.toDataURL("image/png");
  const chartImageBytes_ccs = await fetch(chartDataUrl_ccs).then((res) =>
    res.arrayBuffer()
  );
  const chartImageEmbed_ccs = await pdfDoc.embedPng(chartImageBytes_ccs);
  const chartImageDims_ccs = chartImageEmbed_ccs.scale(0.5);
  const chartPage_ccs = pdfDoc.addPage([pageWidth, pageHeight]);

  let imgw_css = (chartImageDims_ccs.width * 70) / 100;
  let imgh_css = (chartImageDims_ccs.width * 70) / 100;
  chartPage_ccs.drawImage(chartImageEmbed_ccs, {
    x: (chartPage_ccs.getWidth() - imgw_css) / 2,
    y: chartPage_ccs.getHeight() - imgh_css - 50 - margin,
    width: (chartImageDims_ccs.width * 70) / 100,
    height: (chartImageDims_ccs.height * 70) / 100,
  });
  
  yPosition = pageHeight - margin;
  addSectionTitle(chartPage_ccs, "Your Core Skills Analysis", yPosition, 18);

  page = pdfDoc.addPage([pageWidth, pageHeight]);
  yPosition = pageHeight - margin;
  addSectionTitle(page, "Our Recommendations", yPosition, 18);
  yPosition -= 30;
  addSectionTitle(
    page,
    "Suggested Pathway with recommended courses",
    yPosition,
    14,
    timesRomanFont
  );

  yPosition -= 30;

  const addRoadmapSection = (title, items) => {
    addBoldTextBlock(page, title, margin, yPosition);
    yPosition -= lineHeight;
    items.forEach((item) => {
      const text = `${item.Topic}: ${item?.Description || ""}`;
      const lines = wrapTextLink(text, timesRomanFont, 12, maxWidth);
      let linkCounter = 1;
      lines.forEach((line, index) => {
        if (yPosition < margin) {
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          yPosition = pageHeight - margin;
        }
        if (index === 0) {
          drawBulletPoint(page, margin - 20, yPosition - 5);
        }
        addTextBlock(page, line, margin, yPosition);
        yPosition -= lineHeight;
      });

      if (item.links && item.links.length > 0 && showLinks) {
        item.links.forEach((link) => {
          const linkLines = wrapTextLink(link, timesRomanFont, 10, maxWidth);
          const printedLinkCounters = new Set();
          linkLines.forEach((linkLine) => {
            if (yPosition < margin) {
              page = pdfDoc.addPage([pageWidth, pageHeight]);
              yPosition = pageHeight - margin;
            }
            if (!printedLinkCounters.has(linkCounter)) {
              addLinksBlock(
                page,
                `${linkCounter}. ${linkLine}`,
                margin,
                yPosition,
                10,
                { url: link }
              );
              printedLinkCounters.add(linkCounter);
            } else {
              addLinksBlock(page, `${linkLine}`, margin + 20, yPosition, 10, {
                url: link,
              });
            }
            yPosition -= lineHeight;
          });
          linkCounter++;
        });
      }
    });
    yPosition -= lineHeight;
  };

  addRoadmapSection("Certifications", roadmap.Certifications);
  addRoadmapSection("Expand Horizons", roadmap.Expand_Horizons);
  addRoadmapSection("Practice Makes Perfect", roadmap.Practice_Makes_Perfect);
  addRoadmapSection("Solidify Fundamentals", roadmap.Solidify_Fundamentals);
  addRoadmapSection("Reminder", [
    { Topic: "Note", Description: roadmap.Reminder },
  ]);

  page = pdfDoc.addPage([pageWidth, pageHeight]);
  yPosition = pageHeight - margin;

  addSectionTitle(page, "Potential Mentors", yPosition, 18);
  yPosition -= 20;
  addSectionTitle(
    page,
    "Connecting with experienced mentors can provide invaluable guidance and support. We recommend reaching out to the following individuals:",
    yPosition,
    14,
    timesRomanFont
  );
  yPosition -= 30;

  mentorships.forEach((mentor) => {
    const lines = wrapText(
      `Mentor: ${mentor.Mentor_Name}`,
      timesRomanFont,
      12,
      maxWidth
    );
    lines.forEach((line) => {
      if (yPosition < margin) {
        page = pdfDoc.addPage([pageWidth, pageHeight]);
        yPosition = pageHeight - margin;
      }
      drawBulletPoint(page, margin - 20, yPosition - 5);
      addTextBlock(page, line, margin, yPosition);
      yPosition -= lineHeight;
    });

    if (yPosition < margin) {
      page = pdfDoc.addPage([pageWidth, pageHeight]);
      yPosition = pageHeight - margin;
    }
    if (showLinks) {
      addLinksBlock(
        page,
        `Link: ${mentor.Mentor_Link}`,
        margin,
        yPosition,
        10,
        { url: mentor.Mentor_Link }
      );
      yPosition -= 40;
    }
  });

  yPosition -= 30;
  page.drawLine({
    start: { x: margin, y: yPosition },
    end: { x: pageWidth - margin, y: yPosition },
    thickness: 1,
    color: rgb(0, 0, 0),
  });

  yPosition -= 30;
  stext = "Conclusion";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  yPosition -= 30;
  stext =
    "The recommendations outlined in this report, can help you address your skill gaps and enhance your readiness for the target role. Skillmotion.AI is committed to supporting your professional development journey, ensuring you have the tools and resources needed to succeed.";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  yPosition -= 60;
  page.drawLine({
    start: { x: margin, y: yPosition },
    end: { x: pageWidth - margin, y: yPosition },
    thickness: 1,
    color: rgb(0, 0, 0),
  });

  yPosition -= 30;
  stext = "Contact Us: ";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanBoldFont,
    defaultColor
  );

  xPosition = xPosition + timesRomanBoldFont.widthOfTextAtSize(stext, 12);
  stext =
    "For further assistance or questions, please contact Info@skillmotion.ai (Can we create";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  xPosition = margin;
  yPosition = yPosition - 15;
  stext = " some QR code with email ready to be sent?)";
  addSectionText(
    page,
    stext,
    xPosition,
    yPosition,
    12,
    timesRomanFont,
    defaultColor
  );

  drawFooter(page);

  const pdfBytes = await pdfDoc.save();
  // report_Datetime_learnername
  // Create a new Date object
  const now = new Date();

  // Function to format date as DD-MON-YYYY
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two-digit day
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()]; // Get three-letter month name
    const year = date.getFullYear();

    return `${day}_${month}_${year}`; // Use underscores as separators
  };

  // Function to format time as HH-mm-ss
  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}-${minutes}-${seconds}`; // Use hyphens as separators
  };

  // Format the date and time
  const formattedDate = formatDate(now);
  const formattedTime = formatTime(now);

  // Replace spaces with underscores in the user's name
  const report_userName = user?.name?.replace(/\s+/g, '_') || 'Unknown_User';

  // Construct the report name
  const reportname = `Personalised_Roadmap_report_${report_userName}_${formattedDate}_${formattedTime}.pdf`;



  saveAs(
    new Blob([pdfBytes], { type: "application/pdf" }),
    reportname
  );
};

const PdfGenerator = ({
  chartRef,
  chartRef_ccs,
  skillGapRef,
  userName,
  jobRole,
  description,
  showLinks,
  mentorships,
  roadmap,
  skillGapAnalysis,
  currentSkills,
}) => {
  const handleGeneratePdf = () => {
    generatePdf(
      chartRef,
      chartRef_ccs,
      skillGapRef,
      currentSkills,
      skillGapAnalysis,
      roadmap,
      mentorships,
      showLinks,
      userName,
      jobRole,
      description
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", margin: 10 }}>
      <Button className="generate-pdf-btn" onClick={handleGeneratePdf}>
        <DownloadOutlined /> PDF
      </Button>
    </div>
  );
};

export default PdfGenerator;
