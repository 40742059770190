import React, { useState } from "react";
import { Col, Row, Timeline, Image } from "antd";
import LogoImg from "../../SkillLogo.svg";

const SkillAscent = ({ skillAscent, showLinks }) => {

  const urlRegex = /https?:\/\/[^\s]+/g;
  const validURL = (str) => {
    const matches = str?.match(urlRegex);
    if (matches) {
      return true;
    }
    return false;
  };

  return (
    <div className="roadmap-skill">
      <Row>
        <Col span={24}>
          <h5 className="roadmap-description"></h5>
          <div className="roadmap-line">
            <Timeline mode="left">
              <Timeline.Item color="#3F3EED">
                <h5 className="f-16">{"Mentors"}</h5>
                <ul className="mentor-listbar">
                {skillAscent?.length > 0 &&
                  skillAscent?.map((item, index) => {
                    return (
                      <>
                        <li key={index}>
                          <div className="mentorItemWrapper">
                            {validURL(item?.Mentor_OG_Images) && !item?.Mentor_OG_Images?.includes('cdn-cgi') ? (
                              <img width={150} src={item?.Mentor_OG_Images} />
                            ) : (
                              <img width={150} src={LogoImg} />
                            )}

                            <div className="mentor-desc">
                              <h5 className="title">{item?.Mentor_Name}</h5>
                              {showLinks && item?.Mentor_Link ? (
                                <h6>
                                  <a target="_blank" href={item?.Mentor_Link}>
                                    {item?.Mentor_Link}
                                  </a>
                                </h6>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </li>
                      </>
                    );
                  })}
                  </ul>
              </Timeline.Item>
            </Timeline>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col span={24}>
          <h5 className="roadmap-description"></h5>
          <div className="roadmap-line">
            <Timeline mode="left">
              {Array.isArray(skillAscent) &&
                skillAscent?.map((item, index) => {
                  const firstKey = Object.keys(item)[0];
                  {console.log(firstKey,"firstKey")}
                  if (firstKey === "Mentorships") {
                    return (
                      <Timeline.Item color="#5093FC" key={index}>
                        <h5 className="f-16">{firstKey}</h5>
                        {firstKey === "Mentorships" &&
                          typeof firstKey === "string" &&
                          Array.isArray(item[firstKey]) &&
                          item[firstKey]?.map((subItem, index) => {
                            return (
                              <ul key={index}>
                                <div className="mentorItemWrapper">
                                  {validURL(mentorSRC[index]) ? (
                                    <Image width={150} src={mentorSRC[index]} />
                                  ) : (
                                    <Image width={150} src={LogoImg} />
                                  )}

                                  <div>
                                    <h6 className="f-14 head">
                                      {subItem?.Mentor_Name}
                                    </h6>
                                    {showLinks && subItem?.Mentor_Link ? (
                                      <h6 className="f-14 sub">
                                        <a href={subItem?.Mentor_Link}>
                                          {subItem?.Mentor_Link}
                                        </a>
                                      </h6>
                                    ) : null}
                                  </div>
                                </div>
                              </ul>
                            );
                          })}
                      </Timeline.Item>
                    );
                  }
                })}
            </Timeline>
          </div>
        </Col>
      </Row> */}
    </div>
  );
};
export default SkillAscent;
